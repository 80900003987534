import * as React from 'react';
import {
  Route,
  Switch,
  useRouteMatch,
} from 'react-router';
import DashboardDeutschland from './Dashboard/DashboardDeutschland';
import DashboardLandkreise from './Dashboard/DashboardLandkreise';
import ImpfDeutschland from './Impfungen/ImpfDeutschland';

const DashRoutes = ({ firestore, user, firebase }) => {
  let match = useRouteMatch();

  return (
    <div >
      <Switch>
        <Route path={`${match.url}/ueberblick`}>
          {/* <OverviewRoutes firestore={firestore} firebase={firebase} user={user} /> */}
        </Route>
        <Route path={`${match.url}/impfungen`}>
          <ImpfDeutschland />
          {/* <Switch>
            <Route exact path={`${match.url}/impfungen/bundeslaender`}>
              <Impfungen value={impfung} />
            </Route>
            <Route exact path={`${match.url}/impfungen/deutschland`}>
              <ImpfungenDeutschland value={impfung} />
            </Route>
          </Switch> */}
        </Route>
        <Route exact path={`${match.url}/tode`}></Route>
        <Route exact path={`${match.url}/intensivbetten`}></Route>
        <Route path={`${match.url}/landkreise/:county`}>
          <DashboardLandkreise  key={Date.now()}/>
        </Route>
        {/* <Redirect path='/' to='/sars-cov-2' /> */}
        <Route path={match.url}>
          <DashboardDeutschland />
        </Route>
      </Switch>
    </div>
  );
};
export default DashRoutes;
