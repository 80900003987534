import { Card, CardHeader, Grow, IconButton } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import SportsIcon from '@material-ui/icons/Sports';
import LocalCafeIcon from '@material-ui/icons/LocalCafe';
import HelpIcon from '@material-ui/icons/Help';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: { textAlign: 'left', width: '100%' },
}));

const getIcon = (group) => {
  switch (group) {
    case 'Theke':
      return <LocalBarIcon />;
    case 'Service':
      return <RestaurantIcon />;
    case 'Schichtleitung':
      return <SportsIcon />;
    case 'Nothing to do':
      return <LocalCafeIcon />;
    default:
      return <HelpIcon />;
  }
};

const Tile = ({ list }) => {
  const classes = useStyles();

  const timeout = 300;

  return (
    <Grow in={true} timeout={timeout}>
      <Card raised className={classes.root} style={{ display: 'inline-block' }}>
        <CardHeader
          avatar={getIcon(list.data.group)}
          title={list.data.title}
          subheader={list.data.edited && 'Letzte Änderung: ' + list.data.edited?.toDate().toLocaleDateString('de')}
          action={
            <IconButton
              aria-label='laden'
              color='primary'
              component={Link}
              
              to={{
                pathname: '/waldsee/todos/',
                state: list,
              }}
            >
              <ArrowForwardIcon />
            </IconButton>
          }
        />
      </Card>
    </Grow>
  );
};

export default Tile;
