import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Grid, Hidden, Paper, Typography } from '@material-ui/core';
import SiebenTageInzidenzPlot from './SiebenTageInzidenzPlot';
import FälleHistoryPlot from './FälleHistoryPlot';
import DeutschlandKreiseInzidenzTabelle from './DeutschlandKreiseInzidenzTabelle';
import { citys } from '../citys';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    padding: theme.spacing(2),
    maxHeight: '85vh',
    overflow: 'scroll',
  },
  highligtNumber: {
    color: theme.palette.secondary.main,
  },
}));

const DashboardLandkreise = ({}) => {
  const classes = useStyles();
  const [landkreis, setLandkreis] = React.useState();
  const [error, setError] = React.useState();
  const { county } = useParams();
  const getAgs = () => {
    for (var city of citys) {
      if (city.county === county) return city.ags;
    }
    alert('No Valid City Found. Try Again');
    return '09162';
  };
  const ags = getAgs();

  React.useEffect(() => {
    var url = 'https://api.corona-zahlen.org/districts/' + ags;
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.code && response.code === 429)
          setError(response);
        else setLandkreis(response);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <>
      {landkreis &&
      landkreis.data &&
      landkreis.data[ags] &&
      landkreis.data[ags].delta ? (
        <Grid container spacing={1}>
          <Grid item xs={12} sm={8} md={8} lg={9}>
            <Paper className={classes.paper}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <Paper variant='outlined' style={{ height: '100%' }}>
                    {landkreis?.data[ags].weekIncidence ? (
                      <>
                        <Typography
                          variant='h5'
                          component='p'
                          color='secondary'
                        >
                          {(
                            Math.round(
                              landkreis?.data[ags].weekIncidence * (10 ? 10 : 1)
                            ) / (10 ? 10 : 1)
                          ).toLocaleString('de')}
                        </Typography>
                        <Typography>Inzidenz</Typography>
                      </>
                    ) : (
                      ''
                    )}
                    <Divider variant='middle' />

                    <Typography color='secondary' variant='h5' component='h2'>
                      {landkreis?.data[ags].name}
                    </Typography>
                  </Paper>
                </Grid>
                <DisplayValue
                  pre={'+'}
                  value={landkreis?.data[ags].delta.cases}
                  title={'Neue Fälle'}
                  grid={4}
                  secondValue={landkreis?.data[ags].cases}
                />
                <DisplayValue
                  pre={'+'}
                  value={landkreis?.data[ags].delta.deaths}
                  title={'Todesfälle'}
                  grid={4}
                  secondValue={landkreis?.data[ags].deaths}
                />
                <Grid item xs={12}>
                  <Paper variant='outlined'>
                    <SiebenTageInzidenzPlot
                      url={
                        'https://api.corona-zahlen.org/districts/' +
                        ags +
                        '/history/incidence'
                      }
                      county
                      ags={ags}
                      setError={() => setError(x)} error={error}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper variant='outlined'>
                    <FälleHistoryPlot
                      url={
                        'https://api.corona-zahlen.org/districts/' +
                        ags +
                        '/history/cases'
                      }
                      county
                      ags={ags}
                      setError={() => setError(x)} error={error}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper variant='outlined'>Impfungen</Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper variant='outlined'>TBD</Paper>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Hidden xsDown>
            <Grid item xs={12} sm={4} md={4} lg={3}>
              <DeutschlandKreiseInzidenzTabelle setError={() => setError(x)}/>
            </Grid>
          </Hidden>
        </Grid>
      ) : error ? (
        <>
          <Typography variant='h4' color='textSecondary'>
            Error
          </Typography>
          <Typography color='textSecondary'>Type: {error.error}</Typography>
          <Typography color='textSecondary'>
            You can only visit 3 Pages per Minute due to API Restrictions :(
          </Typography>
        </>
      ) : null}
    </>
  );
};
export default DashboardLandkreise;

const DisplayValue = ({
  value,
  title,
  grid,
  pre,
  post,
  round,
  secondValue,
  secondTitle,
  secondRound,
}) => {
  const classes = useStyles();
  return (
    <Grid item xs={grid}>
      <Paper variant='outlined'>
        <Typography variant='h5' component='p' color='secondary'>
          {(pre ? pre : '') +
            '' +
            (
              Math.round(value * (round ? round : 1)) / (round ? round : 1)
            ).toLocaleString('de') +
            '' +
            (post ? post : '')}
        </Typography>
        <Typography>{title}</Typography>
        {secondValue ? (
          <>
            <Divider variant='middle' />

            <Typography variant='h5' component='p' color='secondary'>
              {(
                Math.round(secondValue * (secondRound ? secondRound : 1)) /
                (secondRound ? secondRound : 1)
              ).toLocaleString('de')}
            </Typography>
            <Typography>{secondTitle ? secondTitle : 'Gesamt'}</Typography>
          </>
        ) : (
          ''
        )}
      </Paper>
    </Grid>
  );
};
