import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid, Paper } from '@material-ui/core';
import { ResponsiveLine } from '@nivo/line';
import { Link, useRouteMatch } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  highlightText: {
    fontSize: '3rem',
  },
  subtitleText: {},
  header: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
  },
}));

const ImpfDeutschland = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState();
  const [impfung, setImpfung] = React.useState();
  const [impfungCum, setImpfungCum] = React.useState();

  React.useEffect(() => {
    var url = 'https://api.corona-zahlen.org/vaccinations';
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        setValue(response);
      })
      .catch((err) => console.log(err));
      
    url = 'https://api.corona-zahlen.org/vaccinations/history';

    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);

        const retfirst = [];
        const retsecond = [];
        const retcumfirst = [];
        const retcumsecond = [];
        const ret = [];
        const retcum = [];
        for (let i = 0; i < response.data.history.length; i++) {
          const d = new Date(response.data.history[i].date);
          const h =
            d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear();
          retfirst.push({ x: h, y: response.data.history[i].firstVaccination });
          retsecond.push({
            x: h,
            y: response.data.history[i].secondVaccination,
          });
          if (i !== 0) {
            retcumfirst.push({
              x: h,
              y:
                response.data.history[i].firstVaccination +
                retcumfirst[i - 1].y,
            });
            retcumsecond.push({
              x: h,
              y:
                response.data.history[i].secondVaccination +
                retcumsecond[i - 1].y,
            });
          } else {
            retcumfirst.push({
              x: h,
              y: response.data.history[i].firstVaccination,
            });
            retcumsecond.push({
              x: h,
              y: response.data.history[i].secondVaccination,
            });
          }
        }
        ret.push({ id: 'Erste Impfung', data: retfirst });
        ret.push({ id: 'Zweite Impfung', data: retsecond });
        retcum.push({ id: 'Erste Impfung', data: retcumfirst });
        retcum.push({ id: 'Zweite Impfung', data: retcumsecond });
        setImpfung(ret);
        setImpfungCum(retcum);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      {value && impfung && impfungCum && (
        <>
          <Grid container spacing={3}>
            <Grid item xs>
              <Paper className={classes.paper}>
                <Typography color='secondary' className={classes.highlightText}>
                  {value.data.administeredVaccinations.toLocaleString('de')}
                </Typography>
                <Typography>Verabreichte Impfdosen</Typography>
              </Paper>
            </Grid>
            <Grid item xs>
              <Paper className={classes.paper}>
                <Typography color='secondary' className={classes.highlightText}>
                  {value.data.vaccinated.toLocaleString('de')}
                </Typography>
                <Typography>Erstgeimpfte</Typography>
              </Paper>
            </Grid>
            <Grid item xs>
              <Paper className={classes.paper}>
                <Typography color='secondary' className={classes.highlightText}>
                  {(
                    Math.round((value.data.vaccinated / 83020000) * 100 * 10) /
                    10
                  ).toLocaleString('de') + '%'}
                </Typography>
                <Typography>Mindestens Erstgeimpfte</Typography>
              </Paper>
            </Grid>
          </Grid>
          <Typography className={classes.header} align='left' variant='h5'>
            Impfungen pro Tag
          </Typography>
          <div style={{ height: '20rem' }}>
            <ResponsiveLine
              data={impfung}
              margin={{ top: 50, right: 10, bottom: 50, left: 60 }}
              xScale={{ type: 'time', format: '%d/%m/%Y' }}
              xFormat='time:%d/%m/%Y'
              yScale={{
                type: 'linear',
                min: '0',
                max: 'auto',
                stacked: false,
                reverse: false,
              }}
              yFormat=' >-'
              curve='step'
              axisTop={null}
              axisRight={null}
              axisBottom={{
                orient: 'bottom',
                // tickCount: 7,
                // tickValues: 5,
                format: '%b %d %y',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -45,
                legend: '',
                legendOffset: 36,
                legendPosition: 'middle',
              }}
              axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendOffset: -40,
                legendPosition: 'middle',
              }}
              colors={{ scheme: 'set2' }}
              pointSize={0}
              pointColor={{ theme: 'background' }}
              pointBorderWidth={2}
              pointBorderColor={{ from: 'serieColor' }}
              pointLabelYOffset={-12}
              // enableSlices='x'
              // useMesh={true}
              enableArea={true}
              enableGridX={false}
              theme={{
                "background": "#424242",
                "textColor": "rgba(255, 255, 255, 0.7)",
                "fontSize": 11,
                "axis": {
                    "domain": {
                        "line": {
                            "stroke": "rgba(255, 255, 255, 0.5)",
                            "strokeWidth": 1
                        }
                    },
                    "ticks": {
                        "line": {
                            "stroke": "rgba(255, 255, 255, 0.5)",
                            "strokeWidth": 1
                        }
                    }
                },
                "grid": {
                    "line": {
                        "stroke": "rgba(255, 255, 255, 0.16)",
                        "strokeWidth": 1
                    }
                }
              }}
              legends={[
                {
                  anchor: 'top',
                  direction: 'row',
                  justify: false,
                  translateX: -50,
                  translateY: -27,
                  itemsSpacing: 20,
                  itemDirection: 'left-to-right',
                  itemWidth: 98,
                  itemHeight: 14,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: 'circle',
                  symbolBorderColor: 'rgba(0, 0, 0, .5)',
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemBackground: 'rgba(0, 0, 0, .03)',
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
            />
          </div>
          <Typography className={classes.header} align='left' variant='h5'>
            Summiert
          </Typography>
          <div style={{ height: '20rem' }}>
            <ResponsiveLine
              data={impfungCum}
              margin={{ top: 50, right: 10, bottom: 50, left: 60 }}
              xScale={{ type: 'time', format: '%d/%m/%Y' }}
              xFormat='time:%d/%m/%Y'
              yScale={{
                type: 'linear',
                min: '0',
                max: 'auto',
                stacked: false,
                reverse: false,
              }}
              yFormat=' >-'
              curve='monotoneX'
              axisTop={null}
              axisRight={null}
              axisBottom={{
                orient: 'bottom',
                // tickCount: 7,
                // tickValues: 5,
                format: '%b %d %y',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -45,
                legend: '',
                legendOffset: 36,
                legendPosition: 'middle',
              }}
              axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendOffset: -40,
                legendPosition: 'middle',
              }}
              colors={{ scheme: 'set2' }}
              pointSize={0}
              pointColor={{ theme: 'background' }}
              pointBorderWidth={2}
              pointBorderColor={{ from: 'serieColor' }}
              pointLabelYOffset={-12}
              // enableSlices='x'
              // useMesh={true}
              enableArea={true}
              enableGridX={false}
              theme={{
                "background": "#424242",
                "textColor": "rgba(255, 255, 255, 0.7)",
                "fontSize": 11,
                "axis": {
                    "domain": {
                        "line": {
                            "stroke": "rgba(255, 255, 255, 0.5)",
                            "strokeWidth": 1
                        }
                    },
                    "ticks": {
                        "line": {
                            "stroke": "rgba(255, 255, 255, 0.5)",
                            "strokeWidth": 1
                        }
                    }
                },
                "grid": {
                    "line": {
                        "stroke": "rgba(255, 255, 255, 0.16)",
                        "strokeWidth": 1
                    }
                }
              }}
              legends={[
                {
                  anchor: 'top',
                  direction: 'row',
                  justify: false,
                  translateX: -50,
                  translateY: -27,
                  itemsSpacing: 20,
                  itemDirection: 'left-to-right',
                  itemWidth: 98,
                  itemHeight: 14,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: 'circle',
                  symbolBorderColor: 'rgba(0, 0, 0, .5)',
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemBackground: 'rgba(0, 0, 0, .03)',
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ImpfDeutschland;
