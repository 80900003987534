import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Grid, Hidden, Paper, Typography } from '@material-ui/core';
import SiebenTageInzidenzPlot from './SiebenTageInzidenzPlot';
import FälleHistoryPlot from './FälleHistoryPlot';
import DeutschlandStatesMap from './DeutschlandStatesMap';
import DeutschlandKreiseInzidenzTabelle from './DeutschlandKreiseInzidenzTabelle';
import { Link, useRouteMatch } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    padding: theme.spacing(2),
    // maxHeight: '85vh',
    // overflow: 'scroll',
  },
  highligtNumber: {
    color: theme.palette.secondary.main,
  },
  linkStyle: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    '&:hover': { color: theme.palette.secondary.dark },
  }
}));

const DashboardDeutschland = ({}) => {
  const classes = useStyles();
  const [germany, setGermany] = React.useState();
  const [error, setError] = React.useState();
  React.useEffect(() => {
    var url = 'https://api.corona-zahlen.org/germany';
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.code && response.code === 429)
          setError(response);
        else setGermany(response);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      {germany && germany.r && germany.delta ? (
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={8} lg={6}>
            <Paper className={classes.paper}>
              <Grid container spacing={1}>
                <DisplayValue
                  value={germany.weekIncidence}
                  title={'Inzidenz'}
                  grid={4}
                  round={10}
                  secondValue={germany.r.value}
                  secondTitle={'R-Wert'}
                  // secondTitle={'R-Wert' + getDateString(germany.r.date)}
                  secondRound={100}
                />
                <DisplayValue
                  pre={'+'}
                  value={germany.delta.cases}
                  title={'Neue Fälle'}
                  grid={4}
                  secondValue={germany.cases}
                />
                <DisplayValue
                  pre={'+'}
                  value={germany.delta.deaths}
                  title={'Todesfälle'}
                  grid={4}
                  secondValue={germany.deaths}
                />
                <Grid item xs={12}>
                  <Paper variant='outlined'>
                    <SiebenTageInzidenzPlot
                      url={
                        'https://api.corona-zahlen.org/germany/history/incidence'
                      }
                      setError={(x) => setError(x)}
                      error={error}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper variant='outlined'>
                    <FälleHistoryPlot
                      url={
                        'https://api.corona-zahlen.org/germany/history/cases'
                      }
                      setError={(x) => setError(x)}
                      error={error}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Link
                    to={`/sars-cov-2/impfungen`}
                    className={classes.linkStyle}
                  >
                    <Paper variant='outlined'><Typography className={classes.linkStyle}>Impfungen</Typography></Paper>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <Paper variant='outlined'>TBD</Paper>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {/* <Hidden xsDown> */}
          <Grid item xs={12} sm={7} md={4} lg={3}>
            {/* <Paper> */}
            <DeutschlandKreiseInzidenzTabelle setError={(x) => setError(x)} />
            {/* </Paper> */}
          </Grid>
          {/* </Hidden> */}

          {/* <Hidden smDown> */}
          <Grid item xs={12} sm={5} md={12} lg={3}>
            <Paper className={classes.paper}>
              <DeutschlandStatesMap
                setError={(x) => setError(x)}
                error={error}
              />
            </Paper>
          </Grid>
          {/* </Hidden> */}
        </Grid>
      ) : error ? (
        <>
          <Typography variant='h4' color='textSecondary'>
            Error
          </Typography>
          <Typography color='textSecondary'>Type: {error.error}</Typography>
          <Typography color='textSecondary'>
            You can only visit 3 Pages per Minute due to API Restrictions :(
          </Typography>
        </>
      ) : null}
    </>
  );
};
export default DashboardDeutschland;

const DisplayValue = ({
  value,
  title,
  grid,
  pre,
  post,
  round,
  secondValue,
  secondTitle,
  secondRound,
}) => {
  const classes = useStyles();
  return (
    <Grid item xs={grid}>
      <Paper variant='outlined'>
        <Typography variant='h5' component='p' color='secondary'>
          {(pre ? pre : '') +
            '' +
            (
              Math.round(value * (round ? round : 1)) / (round ? round : 1)
            ).toLocaleString('de') +
            '' +
            (post ? post : '')}
        </Typography>
        <Typography>{title}</Typography>
        {secondValue ? (
          <>
            <Divider variant='middle' />

            <Typography variant='h5' component='p' color='secondary'>
              {(
                Math.round(secondValue * (secondRound ? secondRound : 1)) /
                (secondRound ? secondRound : 1)
              ).toLocaleString('de')}
            </Typography>
            <Typography>{secondTitle ? secondTitle : 'Gesamt'}</Typography>
          </>
        ) : (
          ''
        )}
      </Paper>
    </Grid>
  );
};
