import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import UserRow from './UserRow';

const Console = ({ firestore, firebase, user }) => {
  const [userList, setUserList] = React.useState([]);

  React.useEffect(() => {
    firestore
      .collection('Nutzer')
      .orderBy('name', 'asc')
      .onSnapshot((querySnapshot) => {
        setUserList([]);
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, ' => ', doc.data());
          setUserList((input) => [...input, { id: doc.id, data: doc.data() }]);
        });
      });
    return () => {
      setUserList([]);
    };
  }, [user]);

  return (
    <>
      <Typography variant='h4' align='center'>
        Nutzer*innen bearbeiten:
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>E-Mail</TableCell>
              <TableCell>Subscriber</TableCell>
              <TableCell>Schichtleiter</TableCell>
              <TableCell>Editor</TableCell>
              <TableCell>Admin</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userList.length !== 0 &&
              userList.map(
                (row) => row.data && <UserRow key={row.id} firestore={firestore} user={user} firebase={firebase} row={row} />
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Console;
