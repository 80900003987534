import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const HowTo = ({ children }) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant='h5' gutterBottom>
        Information / How To
      </Typography>
      <Typography gutterBottom>
        Starte, indem du das Trinkeld deiner Schicht in das obere Feld eingibst.
      </Typography>
      <Typography gutterBottom>
        Füge nun alle Namen und Stunden der Personen in Service/Theke deiner
        Schicht hinzu.
      </Typography>
      <Typography gutterBottom>
        Um eine neue Person hinzuzufügen drücke das Plus.
      </Typography>
      <Typography gutterBottom>
        Zum Entfernen einer Person drücke das Minus neben der Person.
      </Typography>
      <Typography gutterBottom>
        Zufrieden mit deinen Eingaben? Dann klick Berechnen und freu dich über
        deine gesparte Zeit :)
      </Typography>
      <Typography gutterBottom>
        Wenn du mir für das Tool danken willst, freue ich mich über eine kleine
        Spende in mein Trinkgeldfach.
      </Typography>
      <Typography gutterBottom>Quirin 💚</Typography>
    </>
  );
};
export default HowTo;
