import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { Box, Checkbox, Fab, FormControl, FormControlLabel, FormGroup, Grid, Paper, TextField, Typography } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    padding: theme.spacing(2),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function EditTodo({ firestore, user, firebase, open, setOpen, todo, listId, items, setItems }) {
  const classes = useStyles();

  const [title, setTitle] = React.useState(todo ? todo.data.title : '');
  const handleChangeTitle = (event) => {
    setTitle(event.target.value);
  };
  const [description, setDescription] = React.useState(todo ? todo.data.description : '');
  const handleChangeDescrition = (event) => {
    setDescription(event.target.value);
  };
  const [checkboxes, setcheckboxes] = React.useState(
    todo ? todo.data.attributes : { forOthers: false, forYou: true, optional: false, summer: true, winter: true }
  );
  const handleChangeBoxes = (event) => {
    setcheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
  };

  React.useEffect(() => {
    if (todo) {
      setTitle(todo.data.title);
      setDescription(todo.data.description);
      setcheckboxes(todo.data.attributes);
    }
  }, [todo]);

  const handleSave = () => {
    firestore.collection('Todolisten').doc(listId).collection('Items').doc(todo.id).update({
      title: title,
      description: description,
      attributes: checkboxes,
      edited: firebase.firestore.FieldValue.serverTimestamp(),
      editedby: user.uid,
    });

    firestore.collection('Todolisten').doc(listId).update({
      edited: firebase.firestore.FieldValue.serverTimestamp(),
      editedby: user.uid,
    });

    const newState = items.map((obj) => {
      if (obj.id === todo.id) {
        return { ...todo, data: { ...todo.data, title: title, description: description, checkboxes: checkboxes } };
      }

      // 👇️ otherwise return object as is
      return obj;
    });
    // console.log(newState);
    setItems(newState);
    setOpen(false);
  };

  const { optional, summer, winter, forYou, forOthers } = checkboxes;

  return (
    <Drawer PaperProps={{ square: false }} anchor={'bottom'} open={open}>
      <Box className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography gutterBottom align='center' variant='h4' component='h2'>
              Todo bearbeiten
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField value={title} onChange={handleChangeTitle} fullWidth id='outlined-textarea' label='Title' variant='outlined' />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={description}
              onChange={handleChangeDescrition}
              fullWidth
              id='outlined-textarea'
              label='Description'
              multiline
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth component='fieldset'>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox color='primary' checked={summer} onChange={handleChangeBoxes} name='summer' />}
                  label={'Sommer'}
                />
                <FormControlLabel
                  control={<Checkbox color='primary' checked={winter} onChange={handleChangeBoxes} name='winter' />}
                  label={'Winter'}
                />
                <FormControlLabel
                  control={<Checkbox color='primary' checked={optional} onChange={handleChangeBoxes} name='optional' />}
                  label={'Optional'}
                />
                <FormControlLabel
                  control={<Checkbox color='primary' checked={forYou} onChange={handleChangeBoxes} name='forYou' />}
                  label={'Für mich'}
                />
                <FormControlLabel
                  control={<Checkbox color='primary' checked={forOthers} onChange={handleChangeBoxes} name='forOthers' />}
                  label={'Für andere'}
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent='space-between' item xs={12}>
              <Fab onClick={() => setOpen(false)} color='secondary' variant='extended'>
                <CloseIcon className={classes.extendedIcon} />
                Abbrechen
              </Fab>
              <Fab onClick={() => handleSave()} color='primary' variant='extended'>
                <SaveIcon className={classes.extendedIcon} />
                Speichern
              </Fab>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
}
