import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Button, Divider, Grid, TextField, Typography, Fab, LinearProgress } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import Todo from './Todo';
import TickedTodo from './TickedTodo';
import OptIn from './OptIn';
import AddIcon from '@material-ui/icons/Add';
import ChooseTemplate from './ChooseTemplate';
import SendMessage from './SendMessage';
import SendIcon from '@material-ui/icons/Send';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'left',
    marginBottom: theme.spacing(8),
  },
  subtitle: {
    marginTop: theme.spacing(1),
  },
  inputfield: {
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  paddingRight: {
    paddingRight: theme.spacing(1),
  },
  templateButton: {
    marginBottom: theme.spacing(2),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: '10000',
  },
}));

const WaldseeTodoList = ({ firestore, firebase, user, userRoles }) => {
  // Binding
  const classes = useStyles();
  const [todos, setTodos] = React.useState([]);
  const [todoText, setTodoText] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [optContent, setOptContent] = React.useState([]);
  const [chooserOpen, setChooserOpen] = React.useState(false);
  const [openMessage, setOpenMessage] = React.useState(false);
  const [possibleTodos, setPossibleTodos] = React.useState([]);

  const [receivedTodos, setReceivedTodos] = React.useState([]);
  React.useEffect(() => {
    user &&
      (user.uid === '6ORFg1OjvhMZEn9PxM692xKfpHU2' || user.uid === 'NXjWVzXSrOh7NjTfUcMCa1dLDVS2') &&
      // (console.log('checkfirestore') ||
      firestore
        .collection('Nutzer')
        .doc(user.uid)
        .collection('receivedTodos')
        .where('checked', '==', false)
        .onSnapshot((querySnapshot) => {
          setReceivedTodos([]);
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, ' => ', doc.data());
            setReceivedTodos((input) => [
              ...input,
              {
                data: {
                  text: doc.data().title,
                  checked: doc.data().checked,
                  type: 'Absender: ' + doc.data().senderName,
                  opt: false,
                  firestoreData: doc.data(),
                  id: doc.id,
                  canReceiveTodos: false,
                },
              },
            ]);
          });
        });
  }, [user]);

  // Side-Effects / Lifecycle
  React.useEffect(() => {
    const existingTodos = localStorage.getItem('todos');
    setTodos(existingTodos ? JSON.parse(existingTodos) : []);
  }, []);

  // Events
  const addTodo = (event) => {
    event.preventDefault();
    if (todoText !== '') {
      const next = [{ text: todoText, checked: false, type: 'custom', opt: false }, ...todos];
      setTodos(next);
      localStorage.setItem('todos', JSON.stringify(next));
      setTodoText('');
    }
  };

  React.useEffect(() => {
    if (todos.length !== 0) {
      localStorage.setItem('todos', JSON.stringify(todos));
    }
  }, [todos]);

  const addTodoFromTemplate = (list) => {
    const daten = [];
    firestore
      .collection('Todolisten')
      .doc(list.id)
      .collection('Items')
      .where('active', '==', true)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, ' => ', doc.data());
          daten.push({ id: doc.id, data: doc.data(), checked: false });
        });
        for (const docId of list.data.order) {
          const document = daten.find((doc) => docId === doc.id);
          if (daten.find((doc) => docId === doc.id)) setTodos((input) => [...input, document]);
        }
      });

    //handle optional?
    //handle other attributes?

    // const copy = [...todos];
    // const noOpt = todolist.filter(({ opt }) => !opt);
    // const toOpt = todolist.filter(({ opt }) => opt);
    // if (toOpt.length !== 0) {
    //   setOptContent(toOpt);
    //   handleClickOpen();
    // }
    // const next = copy.concat(noOpt);
    // setTodos(next);
    // localStorage.setItem('todos', JSON.stringify(next));
  };

  // console.log('todos', todos);
  const updateTodo = (index, checked) => {
    const existingTodos = JSON.parse(localStorage.getItem('todos'));
    existingTodos[index] = {
      id: existingTodos[index].id,
      data: existingTodos[index].data,
      checked: checked,
    };
    setTodos(existingTodos);
    localStorage.setItem('todos', JSON.stringify(existingTodos));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddClose = (toAdd) => {
    let next = [...todos];
    for (let i = 0; i < toAdd.length; i++) {
      next = [{ text: toAdd[i].text, checked: false, type: toAdd[i].type, opt: true }, ...next];
    }
    setTodos(next);
    localStorage.setItem('todos', JSON.stringify(next));
    setOpen(false);
  };

  return (
    <>
      <div className={classes.root}>
        {(todos.length > 0 || receivedTodos.length > 0) && (
          <LinearProgress
            className={classes.templateButton}
            variant='determinate'
            value={(todos.filter((todo) => todo.checked === true).length / (todos.length + receivedTodos.length)) * 100}
          />
        )}
        <ChooseTemplate
          firestore={firestore}
          firebase={firebase}
          open={chooserOpen}
          setOpen={setChooserOpen}
          addTodoFromTemplate={addTodoFromTemplate}
          user={user}
          userRoles={userRoles}
        />
        <form onSubmit={addTodo}>
          <Grid className={classes.inputfield} container justifyContent='flex-start' alignItems='center'>
            <Grid item className={classes.paddingRight}>
              <TextField
                style={{ margin: '0' }}
                fullWidth
                margin='dense'
                label='Todo'
                value={todoText}
                onChange={(e) => setTodoText(e.target.value)}
                id='input'
                variant='outlined'
              />
            </Grid>
            <Grid item>
              <IconButton color='primary' variant='contained' type='submit'>
                <AddIcon />
              </IconButton>
            </Grid>
            {user && (user.uid === '6ORFg1OjvhMZEn9PxM692xKfpHU2' || user.uid === 'NXjWVzXSrOh7NjTfUcMCa1dLDVS2') && (
              <Grid item>
                <IconButton onClick={() => setOpenMessage(!openMessage)} color='primary' variant='contained'>
                  <SendIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </form>
        <Fab className={classes.fab} variant='extended' color='primary' aria-label='add' onClick={() => setChooserOpen(true)}>
          <AddIcon className={classes.extendedIcon} />
          Vorlagen
        </Fab>
        {todos.length !== 0 && !todos.some((e) => e.checked === false) && receivedTodos.length === 0 && (
          <Alert severity='success'>
            <AlertTitle>Geschafft</AlertTitle>
            Du hast alles erledigt — <strong>Genieß deinen Feierabend</strong>
          </Alert>
        )}
        <OptIn open={open} handleClose={handleClose} handleAddClose={handleAddClose} optContent={optContent} />
        {user &&
          (user.uid === '6ORFg1OjvhMZEn9PxM692xKfpHU2' || user.uid === 'NXjWVzXSrOh7NjTfUcMCa1dLDVS2') &&
          receivedTodos &&
          receivedTodos.map(
            ({ data }, index) =>
              !data.checked && (
                <Todo
                  title={undefined}
                  checked={data.checked}
                  onChange={() =>
                    firestore.collection('Nutzer').doc(user.uid).collection('receivedTodos').doc(data.id).update({ checked: true })
                  }
                  opt={data.opt}
                  type={data.type}
                  key={index}
                >
                  {data.text}
                </Todo>
              )
          )}
        {todos.map((todo, index) => !todo.checked && <Todo todo={todo} onChange={() => updateTodo(index, !todo.checked)} key={index} />)}
        <Typography className={classes.subtitle} variant='h5' gutterBottom>
          Erledigt
        </Typography>
        {todos.map((todo, index) => todo.checked && <Todo todo={todo} onChange={() => updateTodo(index, !todo.checked)} key={index} />)}

        {/* {todos.map(
          ({ short, text, checked, type, opt }, index) =>
            checked && (
              <TickedTodo
                title={short ? short : undefined}
                checked={checked}
                onChange={() => updateTodo(index, !checked)}
                opt={opt}
                type={type}
                key={index}
              >
                {text}
              </TickedTodo>
            )
        )} */}
        <Divider className={classes.divider} />
        <Button
          color='secondary'
          onClick={() => {
            let next = [];
            for (let i = 0; i < todos.length; i++) {
              if (todos[i].checked) {
                // console.log('deleted: ' + todos[i].text);
              } else {
                next = next.concat(todos[i]);
              }
            }
            localStorage.setItem('todos', JSON.stringify(next)) || setTodos(next);
          }}
        >
          Erledigte Löschen
        </Button>
        <Button color='secondary' onClick={() => localStorage.setItem('todos', JSON.stringify([])) || setTodos([])}>
          Alles Löschen
        </Button>
        {user && (user.uid === '6ORFg1OjvhMZEn9PxM692xKfpHU2' || user.uid === 'NXjWVzXSrOh7NjTfUcMCa1dLDVS2') && (
          <>
            <SendMessage
              open={openMessage}
              setOpen={setOpenMessage}
              currentTodoText={todoText}
              firestore={firestore}
              firebase={firebase}
              user={user}
            />
          </>
        )}
      </div>
    </>
  );
};
export default WaldseeTodoList;
