import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Calendar from './Calendar';
import LineChart from './LineChart';

const useStyles = makeStyles((theme) => ({
  root: {
    heigth: '500px',
    width: '100%',
  },
}));

const YoutubeHistory = ({ firestore, firebase, user }) => {
  const classes = useStyles();

  const [file, setFile] = React.useState();
  const [arr, setArr] = React.useState();
  const [calData, setCalData] = React.useState();

  React.useEffect(() => {
    calculateMonthly();
  }, [file]);

  const dateFormatter = (time, sym) => {
    const date = new Date(time);
    const y = date.getFullYear();
    const m = date.getMonth() + 1;
    const d = date.getDate();
    return y + sym + ('0' + m).slice(-2) + sym + ('0' + d).slice(-2);
    // return d + '/' + m + '/' + y;
  };
  const calculateMonthly = () => {
    if (file === undefined) return;
    var arr = [];

    arr.push({ value: 1, day: dateFormatter(file[0].time, '-') });
    var val = 0;
    var valdat = '';
    for (let i = 1; i < file.length; i++) {
      let tmpDate = dateFormatter(file[i].time, '-');
      if (arr[arr.length - 1].value > val) {
        val = arr[arr.length - 1].value;
        valdat = arr[arr.length - 1].day;
      }
      if (tmpDate.localeCompare(arr[arr.length - 1].day) === 0) {
        arr[arr.length - 1] = {
          value: arr[arr.length - 1].value + 1,
          day: arr[arr.length - 1].day,
        };
      } else {
        arr.push({ value: 1, day: tmpDate });
      }
    }

    const newarr = [...arr]
    setCalData(newarr);
    console.log(calData)

    // console.log(arr);
    // console.log(val, valdat);

    arr = arr.reverse();
    //unneccesary
    for (let i = 0; i < arr.length; i++) {
      arr[i] = { x: i + 1, y: arr[i].value };
    }
    setArr(arr);
  };

  const handleChange = (e) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], 'UTF-8');
    fileReader.onload = (e) => {
      // console.log('e.target.result', e.target.result);
      setFile(JSON.parse(e.target.result));
    };
  };

  // console.log(arr);
  return (
    <>
      <Button variant='contained' component='label'>
        Upload File
        <input onChange={handleChange} type='file' hidden />
      </Button>

      {arr && (
        <>
          {/* <div style={{ height: '800px', width: '100wh' }}>
            <Calendar data={calData} start={dateFormatter(file[file.length-1].time),'-'} end={dateFormatter(file[0].time,'-')}/>
          </div> */}
          <div style={{ height: '800px', width: '100wh' }}>
            <LineChart data={arr} />
          </div>
        </>
      )}
    </>
  );
};
export default YoutubeHistory;
