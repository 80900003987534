import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { Box, Checkbox, Fab, FormControl, FormControlLabel, FormGroup, Grid, Paper, TextField, Typography } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    padding: theme.spacing(2),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function NewList({ firestore, user, firebase, open, setOpen }) {
  const classes = useStyles();

  const [title, setTitle] = React.useState('');
  const handleChangeTitle = (event) => {
    setTitle(event.target.value);
  };
  const [group, setGroup] = React.useState('');
  const handleChangeGroup = (event) => {
    setGroup(event.target.value);
  };

  const [checkboxPub, setCheckboxPub] = React.useState(false);

  const [checkboxAct, setCheckboxAct] = React.useState(false);

  const handleSave = () => {
    firestore
      .collection('Todolisten')
      .add({
        title: title,
        group: group,
        public: checkboxPub,
        active: checkboxAct,
        edited: firebase.firestore.FieldValue.serverTimestamp(),
        editedby: user.uid,
        order: [],
        created: firebase.firestore.FieldValue.serverTimestamp(),
        createdby: user.uid,
        sequence: 99,
      })
      .then((docRef) => {
        firestore.collection('Todolisten').doc(docRef.id).collection('Analytics').doc('UserBehaviour').set({ uses: 0 });
      });
    setOpen(false);
    setTitle('');
    setGroup('');
    setCheckboxPub(false);
    setCheckboxAct(false);
  };

  return (
    <Drawer PaperProps={{ square: false }} anchor={'bottom'} open={open}>
      <Box className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography gutterBottom align='center' variant='h4' component='h2'>
              Neue Liste
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField value={title} onChange={handleChangeTitle} fullWidth id='outlined-textarea' label='Title' variant='outlined' />
          </Grid>
          <Grid item xs={12}>
            <TextField value={group} onChange={handleChangeGroup} fullWidth id='outlined-textarea' label='Gruppe' variant='outlined' />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth component='fieldset'>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox color='primary' checked={checkboxPub} onChange={(e) => setCheckboxPub(e.target.checked)} />}
                  label={'Öffentlich'}
                />
                <FormControlLabel
                  control={<Checkbox color='primary' checked={checkboxAct} onChange={(e) => setCheckboxAct(e.target.checked)} />}
                  label={'Aktiv'}
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent='space-between' item xs={12}>
              <Fab onClick={() => setOpen(false)} color='secondary' variant='extended'>
                <CloseIcon className={classes.extendedIcon} />
                Abbrechen
              </Fab>
              <Fab onClick={() => handleSave()} color='primary' variant='extended'>
                <SaveIcon className={classes.extendedIcon} />
                Speichern
              </Fab>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
}
