const checkAuthorization = (user, allowedRoles) => {
  if (!user) return false;
  for (const role of allowedRoles) {
    if (user.roles[role]) {
      return true;
    }
  }
  return false;
};

export const canRead = (user) => {
  const allowed = ['admin', 'editor', 'schichtleiter', 'subscriber'];
  return checkAuthorization(user, allowed);
};
export const canReadPrivilige = (user) => {
  const allowed = ['admin', 'editor', 'schichtleiter'];
  return checkAuthorization(user, allowed);
};
export const canEdit = (user) => {
  const allowed = ['admin', 'editor'];
  return checkAuthorization(user, allowed);
};
export const canCreate = (user) => {
  const allowed = ['admin', 'editor'];
  return checkAuthorization(user, allowed);
};
export const canDelete = (user) => {
  const allowed = ['admin'];
  return checkAuthorization(user, allowed);
};
export const isAdmin = (user) => {
  const allowed = ['admin'];
  return checkAuthorization(user, allowed);
};
export default { canRead, canReadPrivilige, canEdit, canCreate, canDelete, isAdmin };
