import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import Brightness2Icon from '@material-ui/icons/Brightness2';
import Brightness3Icon from '@material-ui/icons/Brightness3';
import LockIcon from '@material-ui/icons/Lock';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import { service_end } from './templates/service_end';
import { service_abend } from './templates/service_abend';
import { service_tag } from './templates/service_tag';
import { schichtleitung } from './templates/schichtleitung';
import { schichtleitung_mtg } from './templates/schichtleitung_mtg';
import { schichtleitung_hochzeit } from './templates/schichtleitung_hochzeit';
import { nothing_to_do } from './templates/nothing_to_do';
import { theke_tag } from './templates/theke_tag';
import { theke_abend } from './templates/theke_abend';
import { theke_saal } from './templates/theke_saal';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function ChooseTemplate({ open, setOpen, addTodoFromTemplate, user }) {
  const classes = useStyles();
  const [openService, setOpenService] = React.useState(false);
  const [openTheke, setOpenTheke] = React.useState(false);
  const [openSchichtleitung, setOpenSchichtleitung] = React.useState(false);

  const handleClickSchichtleitung = () => {
    setOpenSchichtleitung(!openSchichtleitung);
  };
  const handleClickService = () => {
    setOpenService(!openService);
  };
  const handleClickTheke = () => {
    setOpenTheke(!openTheke);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>{'Schichtauswahl'}</DialogTitle>
        <DialogContent>
          <List component='nav' aria-labelledby='nested-list-subheader' className={classes.root}>
            {user &&
            (user.uid === '6ORFg1OjvhMZEn9PxM692xKfpHU2' ||
              user.uid === '0joABVyyVhY4NfrDv9CiCpaCmF33' ||
              user.uid === 'CvEq9Y2V2Td6DqKd7pE6T9dQQnl2' ||
              user.uid === 'NXjWVzXSrOh7NjTfUcMCa1dLDVS2') ? (
              <>
                <ListItem button onClick={handleClickSchichtleitung}>
                  <ListItemIcon>
                    <RecordVoiceOverIcon />
                  </ListItemIcon>
                  <ListItemText primary='Schichtleitung' />
                  {openSchichtleitung ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openSchichtleitung} timeout='auto' unmountOnExit>
                  <List component='div' disablePadding>
                    <ListItem
                      button
                      onClick={() => handleClickService() || handleClose() || addTodoFromTemplate(schichtleitung)}
                      className={classes.nested}
                    >
                      <ListItemIcon>
                        <RestaurantIcon />
                      </ListItemIcon>
                      <ListItemText primary='Normal' />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => handleClickService() || handleClose() || addTodoFromTemplate(schichtleitung_mtg)}
                      className={classes.nested}
                    >
                      <ListItemIcon>
                        <LocalBarIcon />
                      </ListItemIcon>
                      <ListItemText primary='Moove to Groove' />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => handleClickService() || handleClose() || addTodoFromTemplate(schichtleitung_hochzeit)}
                      className={classes.nested}
                    >
                      <ListItemIcon>
                        <SettingsEthernetIcon />
                      </ListItemIcon>
                      <ListItemText primary='Hochzeit' />
                    </ListItem>
                  </List>
                </Collapse>
              </>
            ) : (
              <ListItem button disabled>
                <ListItemIcon>
                  <LockIcon />
                </ListItemIcon>
                <ListItemText primary='Schichtleitung' />
              </ListItem>
            )}
            <ListItem button onClick={handleClickService}>
              <ListItemIcon>
                <RestaurantIcon />
              </ListItemIcon>
              <ListItemText primary='Service' />
              {openService ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openService} timeout='auto' unmountOnExit>
              <List component='div' disablePadding>
                <ListItem button disabled className={classes.nested}>
                  <ListItemIcon>
                    <Brightness1Icon />
                  </ListItemIcon>
                  <ListItemText primary='tbd Brunch' />
                </ListItem>
                <ListItem
                  button
                  onClick={() => handleClickService() || handleClose() || addTodoFromTemplate(service_tag)}
                  className={classes.nested}
                >
                  <ListItemIcon>
                    <Brightness1Icon />
                  </ListItemIcon>
                  <ListItemText primary='Tag' />
                </ListItem>
                <ListItem
                  button
                  onClick={() => handleClickService() || handleClose() || addTodoFromTemplate(service_abend)}
                  className={classes.nested}
                >
                  <ListItemIcon>
                    <Brightness2Icon />
                  </ListItemIcon>
                  <ListItemText primary='Abend' />
                </ListItem>
                <ListItem button disabled className={classes.nested}>
                  <ListItemIcon>
                    <Brightness1Icon />
                  </ListItemIcon>
                  <ListItemText primary='tbd Hochzeit Eindecken' />
                </ListItem>
                <ListItem button disabled className={classes.nested}>
                  <ListItemIcon>
                    <Brightness2Icon />
                  </ListItemIcon>
                  <ListItemText primary='tbd Hochzeit Abend' />
                </ListItem>
                <ListItem
                  button
                  onClick={() => handleClickService() || handleClose() || addTodoFromTemplate(service_end)}
                  className={classes.nested}
                >
                  <ListItemIcon>
                    <Brightness3Icon />
                  </ListItemIcon>
                  <ListItemText primary='Hochzeit End' />
                </ListItem>
              </List>
            </Collapse>

            <ListItem button onClick={handleClickTheke}>
              <ListItemIcon>
                <LocalBarIcon />
              </ListItemIcon>
              <ListItemText primary='Theke' />
              {openTheke ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openTheke} timeout='auto' unmountOnExit>
              <List component='div' disablePadding>
                <ListItem
                  button
                  onClick={() => handleClickService() || handleClose() || addTodoFromTemplate(theke_tag)}
                  className={classes.nested}
                >
                  <ListItemIcon>
                    <Brightness1Icon />
                  </ListItemIcon>
                  <ListItemText primary='Tag' />
                </ListItem>
                <ListItem
                  button
                  onClick={() => handleClickService() || handleClose() || addTodoFromTemplate(theke_abend)}
                  className={classes.nested}
                >
                  <ListItemIcon>
                    <Brightness2Icon />
                  </ListItemIcon>
                  <ListItemText primary='Abend' />
                </ListItem>
                <ListItem
                  button
                  onClick={() => handleClickService() || handleClose() || addTodoFromTemplate(theke_saal)}
                  className={classes.nested}
                >
                  <ListItemIcon>
                    <LocalBarIcon />
                  </ListItemIcon>
                  <ListItemText primary='Saal' />
                </ListItem>
              </List>
            </Collapse>
            <ListItem button onClick={() => handleClickService() || handleClose() || addTodoFromTemplate(nothing_to_do)}>
              <ListItemIcon>
                <AccessibilityNewIcon />
              </ListItemIcon>
              <ListItemText primary='Nothing to do' />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handleClose} color='primary' autoFocus>
            Abbrechen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
