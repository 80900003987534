import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, Chip, Grid, IconButton, Paper, Typography } from '@material-ui/core';
import CustomChip from './CustomChip';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {},
  text: {
    marginTop: theme.spacing(1),
  },
  todo: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  customAccordion: {
    '&::before': {
      height: '0px',
    },
    margin: '0',
  },
  customAccordionSummary: {
    padding: '0',
    margin: '0',
  },
}));

const Todo = ({ todo, onChange }) => {
  const classes = useStyles();
  return (
    <>
      {todo.data.description ? (
        <Paper className={classes.todo}>
          <Grid container justifyContent='flex-start' alignItems='center' wrap='nowrap'>
            <Grid item>
              <Checkbox color='default' checked={todo.checked} onChange={onChange} />
            </Grid>
            <Grid item xs>
              <CustomChip todo={todo} />
              <Accordion
                elevation={0}
                classes={{ root: classes.customAccordion, expanded: classes.customAccordion }}
                style={{ marginTop: '0px' }}
              >
                <AccordionSummary
                  classes={{ root: classes.customAccordionSummary, expanded: classes.customAccordionSummary }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                >
                  <Typography>{todo.data.title}</Typography>
                </AccordionSummary>
                <AccordionDetails classes={{ root: classes.customAccordionSummary }}>
                  <Typography>{todo.data.description}</Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Paper>
      ) : (
        <Paper className={classes.todo}>
          <Grid container justifyContent='flex-start' alignItems='center' wrap='nowrap'>
            <Grid item>
              <Checkbox color='default' checked={todo.checked} onChange={onChange} />
            </Grid>
            <Grid item xs>
            <CustomChip todo={todo} />

              <Typography className={classes.text}>{todo.data.title}</Typography>
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
};
export default Todo;
