import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Divider, Grid, ListSubheader, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  menuItem: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function SelectList({ value, setValue, todoLists }) {
  const classes = useStyles();

  const handleChange = (event) => {
    setValue(event.target.value ? event.target.value : '');
  };

  const getGroupedMenuItems = () => {
    var items = [];
    var remember = '';
    for (let i = 0; i < todoLists.length; i++) {
      if (todoLists[i].data.group !== remember) {
        items.push(
          <ListSubheader color='primary' key={todoLists[i].data.group}>
            {todoLists[i].data.group}
          </ListSubheader>
        );
        remember = todoLists[i].data.group;
      }
      items.push(
        <MenuItem className={classes.menuItem} value={todoLists[i].id} key={i}>
          <Grid container justifyContent='space-between'>
            <Grid item xs>
              <Typography variant='body1'>{todoLists[i].data.title} </Typography>
            </Grid>
            <Grid item>
              <Typography variant='body2' color='textSecondary'>
                {todoLists[i].data.public ? 'öffentlich' : 'login'} | {todoLists[i].data.active ? 'aktiv' : 'inaktiv'}
              </Typography>
            </Grid>
          </Grid>
        </MenuItem>
      );
    }
    return items;
  };

  return (
    <div>
      <FormControl fullWidth variant='outlined' className={classes.formControl}>
        <InputLabel htmlFor='grouped-select' id='choose-todolist'>
          Liste
        </InputLabel>
        <Select labelId='choose-todolist' defaultValue='' id='chooseList' value={value} onChange={handleChange} label='Liste'>
          {todoLists && getGroupedMenuItems()}
        </Select>
      </FormControl>
    </div>
  );
}
