import * as React from 'react';
import './App.css';
import { NavSideBar, SpeedDial, ResponsiveNavBar } from './components/Frame';
import { SnackbarProvider } from 'notistack';

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';

// import { ChartHome } from './components/Charts';
import { Routes } from './components/Routing';
import { Button, createMuiTheme, ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { blue, green, pink } from '@material-ui/core/colors';
if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: 'AIzaSyDqBav-dW0jOTfXkjR9wajy7CMMxClo5dc',
    authDomain: 'qweninger-accfe.firebaseapp.com',
    projectId: 'qweninger-accfe',
    storageBucket: 'qweninger-accfe.appspot.com',
    messagingSenderId: '1024705046016',
    appId: '1:1024705046016:web:be04c75dd04285504b5ae5',
    measurementId: 'G-88KD2E0ZKD',
  });
} else {
  firebase.app(); // if already initialized, use that one
}

const auth = firebase.auth();
const firestore = firebase.firestore();

const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      // light: will be calculated from palette.primary.main,
      main: green[500],
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: '#0066ff',
      main: pink['A400'],
      // dark: will be calculated from palette.secondary.main,
      // contrastText: '#ffcc00',
    },
  },
});

function App() {
  const [user] = useAuthState(auth);

  const [userRoles, setUserRoles] = React.useState();

  React.useEffect(() => {
    if (user) {
      firestore
        .collection('Nutzer')
        .doc(user.uid)
        .collection('secureData')
        .doc('secureDoc')
        .onSnapshot((doc) => setUserRoles(doc.data()));
    } else {
      setUserRoles();
    }
  }, [user]);

  return (
    <div className='App'>
      {/* {user && user.uid === '6ORFg1OjvhMZEn9PxM692xKfpHU2' ? ( */}
      {/* {user ? ( */}
      <ThemeProvider theme={theme}>
        <ResponsiveNavBar auth={auth} signIn={<SignIn firebase={firebase} auth={auth} />} user={user} userRoles={userRoles} style={{ position: 'relative' }}>
          <SnackbarProvider maxSnack={3} hideIconVariant>
            <Routes firestore={firestore} user={user} firebase={firebase} userRoles={userRoles} />
          </SnackbarProvider>
        </ResponsiveNavBar>
      </ThemeProvider>
      {/* <SpeedDial /> */}
    </div>
  );
}

// const usersRef = db.collection('Nutzer').doc('id');

//     usersRef.get().then((docSnapshot) => {
//       if (docSnapshot.exists) {
//         usersRef.onSnapshot((doc) => {
//           // do stuff with the data
//         });
//       } else {
//         usersRef.set({}); // create the document
//       }
//     });

function SignIn() {
  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider).then((result) => {
      const usersRef = firebase.firestore().collection('Nutzer').doc(result.user.uid);

      usersRef.get().then((docSnapshot) => {
        if (docSnapshot.exists) {
          usersRef.onSnapshot((doc) => {
            // SNACKBAR WELCOME MESSAGE?
          });
        } else {
          usersRef.set({
            name: result.user.displayName,
            email: result.user.email,
            photo: result.user.photoURL,
            received: [],
            history: [],
            queries: 0,
          }); // create the document
          usersRef
            .collection('secureData')
            .doc('secureDoc')
            .set({
              roles: { subscriber: true, schichtleiter: false, editor: false, admin: false },
            });
        }
      });
    });
  };
  return (
    <Button color='inherit' onClick={signInWithGoogle}>
      Anmelden
    </Button>
  );
}

export default App;
