import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { Box, Checkbox, Fab, FormControl, FormControlLabel, FormGroup, Grid, Paper, TextField, Typography } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    padding: theme.spacing(2),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function NewTodo({ firestore, user, firebase, open, setOpen, listId, items, setItems, order, setOrder }) {
  const classes = useStyles();

  const [title, setTitle] = React.useState('');
  const handleChangeTitle = (event) => {
    setTitle(event.target.value);
  };
  const [description, setDescription] = React.useState('');
  const handleChangeDescrition = (event) => {
    setDescription(event.target.value);
  };
  const [checkboxes, setCheckboxes] = React.useState({ forOthers: false, forYou: true, optional: false, summer: true, winter: true });
  const handleChangeBoxes = (event) => {
    setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
  };

  const handleSave = () => {
    firestore
      .collection('Todolisten')
      .doc(listId)
      .collection('Items')
      .add({
        title: title,
        description: description,
        attributes: checkboxes,
        active: true,
        created: firebase.firestore.FieldValue.serverTimestamp(),
        createdby: user.uid,
        edited: firebase.firestore.FieldValue.serverTimestamp(),
        editedby: user.uid,
      })
      .then((docRef) => {
        setItems([
          ...items,
          {
            id: docRef.id,
            data: {
              title: title,
              description: description,
              attributes: checkboxes,
              active: true,
              createdby: user.uid,
              editedby: user.uid,
            },
          },
        ]);
        setOrder([...order, docRef.id]);
        firestore
          .collection('Todolisten')
          .doc(listId)
          .update({
            order: [...order, docRef.id],
          });
      });
    setOpen(false);
    setTitle('');
    setDescription('');
    setCheckboxes({ forOthers: false, forYou: true, optional: false, summer: true, winter: true });
  };

  const { optional, summer, winter, forYou, forOthers } = checkboxes;

  return (
    <Drawer PaperProps={{ square: false }} anchor={'bottom'} open={open}>
      <Box className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography gutterBottom align='center' variant='h4' component='h2'>
              Neues Todo
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoFocus
              value={title}
              onChange={handleChangeTitle}
              fullWidth
              id='outlined-textarea'
              label='Title'
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={description}
              onChange={handleChangeDescrition}
              fullWidth
              id='outlined-textarea'
              label='Description'
              multiline
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth component='fieldset'>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox color='primary' checked={summer} onChange={handleChangeBoxes} name='summer' />}
                  label={'Sommer'}
                />
                <FormControlLabel
                  control={<Checkbox color='primary' checked={winter} onChange={handleChangeBoxes} name='winter' />}
                  label={'Winter'}
                />
                <FormControlLabel
                  control={<Checkbox color='primary' checked={optional} onChange={handleChangeBoxes} name='optional' />}
                  label={'Optional'}
                />
                <FormControlLabel
                  control={<Checkbox color='primary' checked={forYou} onChange={handleChangeBoxes} name='forYou' />}
                  label={'Für mich'}
                />
                <FormControlLabel
                  control={<Checkbox color='primary' checked={forOthers} onChange={handleChangeBoxes} name='forOthers' />}
                  label={'Für andere'}
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent='space-between' item xs={12}>
              <Fab onClick={() => setOpen(false)} color='secondary' variant='extended'>
                <CloseIcon className={classes.extendedIcon} />
                Abbrechen
              </Fab>
              <Fab onClick={() => handleSave()} color='primary' variant='extended'>
                <SaveIcon className={classes.extendedIcon} />
                Speichern
              </Fab>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
}
