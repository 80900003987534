export const mitarbeiter = [
  {
    name: 'Quirin',
    bereich: ['Service, Theke, Schichtleitung'],
  },
  {
    name: 'Johnny',
    bereich: ['Service, Theke, Schichtleitung'],
  },
  {
    name: 'Janosch',
    bereich: ['Service, Theke, Schichtleitung'],
  },
  {
    name: 'Bea',
    bereich: ['Service, Theke, Schichtleitung'],
  },
  {
    name: 'Pascale',
    bereich: ['Service, Theke, Schichtleitung'],
  },
  {
    name: 'Svenja',
    bereich: ['Service, Theke, Schichtleitung'],
  },
  {
    name: 'Luke',
    bereich: ['Theke, Schichtleitung'],
  },
  {
    name: 'Franzi',
    bereich: ['Service, Theke, Schichtleitung'],
  },
  {
    name: 'Simon',
    bereich: ['Theke, Schichtleitung'],
  },
  {
    name: 'Stille',
    bereich: ['Service'],
  },
  {
    name: 'Tino',
    bereich: ['Service'],
  },
  {
    name: 'Helena',
    bereich: ['Service'],
  },
  {
    name: 'Charly',
    bereich: ['Service'],
  },
  {
    name: 'Timon',
    bereich: ['Service'],
  },
  {
    name: 'LukASS',
    bereich: ['Service'],
  },
  {
    name: 'Jakob',
    bereich: ['Service'],
  },
  {
    name: 'Linus',
    bereich: ['Service'],
  },
  {
    name: 'Ole',
    bereich: ['Service'],
  },
  {
    name: 'Alina',
    bereich: ['Service'],
  },
  {
    name: 'Maylin',
    bereich: ['Service'],
  },
  {
    name: 'Maurus',
    bereich: ['Service'],
  },
  {
    name: 'Evi',
    bereich: ['Service'],
  },
  {
    name: 'Kamesh',
    bereich: ['Service'],
  },
  {
    name: 'Sedy',
    bereich: ['Service'],
  },
  {
    name: 'Raffa',
    bereich: ['Service'],
  },
  {
    name: 'Joel',
    bereich: ['Theke'],
  },
  {
    name: 'Nina',
    bereich: ['Service'],
  },
  {
    name: 'Toska',
    bereich: ['Service'],
  },
  {
    name: 'Wolf',
    bereich: ['Theke'],
  },
  {
    name: 'Chris',
    bereich: ['Theke'],
  },
  {
    name: 'Gisela',
    bereich: ['Service'],
  },
  {
    name: 'Jannis',
    bereich: ['Service'],
  },
  {
    name: 'Morgan',
    bereich: ['Theke'],
  },
  {
    name: 'Benneth',
    bereich: ['Service'],
  },
  {
    name: 'Lena',
    bereich: ['Service'],
  },
  {
    name: 'Tina',
    bereich: ['Service'],
  },
  {
    name: 'Heinrich',
    bereich: ['Theke'],
  },
  {
    name: 'Probeschicht',
    bereich: ['Service'],
  },
];
