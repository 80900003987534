import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IconButton, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

export default function DeleteAlert({ firestore, firebase, user, list, element }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    firestore
      .collection('Todolisten')
      .doc(list.id)
      .update({
        order: list.data.order.filter((id) => id !== element.id),
      }) &&
      firestore
        .collection('Todolisten')
        .doc(list.id)
        .collection('Items')
        .doc(element.id)
        .update({
          active: false,
        })
        .then(() => {
          console.log('Document successfully deleted!');
        })
        .catch((error) => {
          console.error('Error removing document: ', error);
        });

    setOpen(false);
  };

  return (
    <div>
      <IconButton size='small' color='primary' aria-label='delete' onClick={handleClickOpen}>
        <DeleteIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>{'Unwiderruflich löschen?'}</DialogTitle>
        <DialogContent>
          {element && (
            <DialogContentText id='alert-dialog-description'>
              Warnung! Nicht widerherstellbar! Möchtest du das Todo wirklich löschen? <br />
              <br />
              <b>Folgendes Dokument wird gelöscht:</b>
              <br />
              <b>Titel:</b>
              <br />
              {element.data.title}
              <br />
              <b>Beschreibung:</b> <br />
              {element.data.description}
              <br />
              <b>DokumentID:</b>
              <br />
              {element.id}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Abbrechen
          </Button>
          <Button onClick={() => handleDelete()} color='primary' autoFocus>
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
