export const citys = [
    {
        ags: "10041",
        county: "LK Stadtverband Saarbrücken"
    },
    {
        ags: "10042",
        county: "LK Merzig-Wadern"
    },
    {
        ags: "10043",
        county: "LK Neunkirchen"
    },
    {
        ags: "10044",
        county: "LK Saarlouis"
    },
    {
        ags: "10045",
        county: "LK Saar-Pfalz-Kreis"
    },
    {
        ags: "10046",
        county: "LK Sankt Wendel"
    },
    {
        ags: "11001",
        county: "SK Berlin Mitte"
    },
    {
        ags: "11002",
        county: "SK Berlin Friedrichshain-Kreuzberg"
    },
    {
        ags: "11003",
        county: "SK Berlin Pankow"
    },
    {
        ags: "11004",
        county: "SK Berlin Charlottenburg-Wilmersdorf"
    },
    {
        ags: "11005",
        county: "SK Berlin Spandau"
    },
    {
        ags: "11006",
        county: "SK Berlin Steglitz-Zehlendorf"
    },
    {
        ags: "11007",
        county: "SK Berlin Tempelhof-Schöneberg"
    },
    {
        ags: "11008",
        county: "SK Berlin Neukölln"
    },
    {
        ags: "11009",
        county: "SK Berlin Treptow-Köpenick"
    },
    {
        ags: "11010",
        county: "SK Berlin Marzahn-Hellersdorf"
    },
    {
        ags: "11011",
        county: "SK Berlin Lichtenberg"
    },
    {
        ags: "11012",
        county: "SK Berlin Reinickendorf"
    },
    {
        ags: "12051",
        county: "SK Brandenburg a.d.Havel"
    },
    {
        ags: "12052",
        county: "SK Cottbus"
    },
    {
        ags: "12053",
        county: "SK Frankfurt (Oder)"
    },
    {
        ags: "12054",
        county: "SK Potsdam"
    },
    {
        ags: "12060",
        county: "LK Barnim"
    },
    {
        ags: "12061",
        county: "LK Dahme-Spreewald"
    },
    {
        ags: "12062",
        county: "LK Elbe-Elster"
    },
    {
        ags: "12063",
        county: "LK Havelland"
    },
    {
        ags: "12064",
        county: "LK Märkisch-Oderland"
    },
    {
        ags: "12065",
        county: "LK Oberhavel"
    },
    {
        ags: "12066",
        county: "LK Oberspreewald-Lausitz"
    },
    {
        ags: "12067",
        county: "LK Oder-Spree"
    },
    {
        ags: "12068",
        county: "LK Ostprignitz-Ruppin"
    },
    {
        ags: "12069",
        county: "LK Potsdam-Mittelmark"
    },
    {
        ags: "12070",
        county: "LK Prignitz"
    },
    {
        ags: "12071",
        county: "LK Spree-Neiße"
    },
    {
        ags: "12072",
        county: "LK Teltow-Fläming"
    },
    {
        ags: "12073",
        county: "LK Uckermark"
    },
    {
        ags: "13003",
        county: "SK Rostock"
    },
    {
        ags: "13004",
        county: "SK Schwerin"
    },
    {
        ags: "13071",
        county: "LK Mecklenburgische Seenplatte"
    },
    {
        ags: "13072",
        county: "LK Rostock"
    },
    {
        ags: "13073",
        county: "LK Vorpommern-Rügen"
    },
    {
        ags: "13074",
        county: "LK Nordwestmecklenburg"
    },
    {
        ags: "13075",
        county: "LK Vorpommern-Greifswald"
    },
    {
        ags: "13076",
        county: "LK Ludwigslust-Parchim"
    },
    {
        ags: "14511",
        county: "SK Chemnitz"
    },
    {
        ags: "14521",
        county: "LK Erzgebirgskreis"
    },
    {
        ags: "14522",
        county: "LK Mittelsachsen"
    },
    {
        ags: "14523",
        county: "LK Vogtlandkreis"
    },
    {
        ags: "14524",
        county: "LK Zwickau"
    },
    {
        ags: "14612",
        county: "SK Dresden"
    },
    {
        ags: "14625",
        county: "LK Bautzen"
    },
    {
        ags: "14626",
        county: "LK Görlitz"
    },
    {
        ags: "14627",
        county: "LK Meißen"
    },
    {
        ags: "14628",
        county: "LK Sächsische Schweiz-Osterzgebirge"
    },
    {
        ags: "14713",
        county: "SK Leipzig"
    },
    {
        ags: "14729",
        county: "LK Leipzig"
    },
    {
        ags: "14730",
        county: "LK Nordsachsen"
    },
    {
        ags: "15001",
        county: "SK Dessau-Roßlau"
    },
    {
        ags: "15002",
        county: "SK Halle"
    },
    {
        ags: "15003",
        county: "SK Magdeburg"
    },
    {
        ags: "15081",
        county: "LK Altmarkkreis Salzwedel"
    },
    {
        ags: "15082",
        county: "LK Anhalt-Bitterfeld"
    },
    {
        ags: "15083",
        county: "LK Börde"
    },
    {
        ags: "15084",
        county: "LK Burgenlandkreis"
    },
    {
        ags: "15085",
        county: "LK Harz"
    },
    {
        ags: "15086",
        county: "LK Jerichower Land"
    },
    {
        ags: "15087",
        county: "LK Mansfeld-Südharz"
    },
    {
        ags: "15088",
        county: "LK Saalekreis"
    },
    {
        ags: "15089",
        county: "LK Salzlandkreis"
    },
    {
        ags: "15090",
        county: "LK Stendal"
    },
    {
        ags: "15091",
        county: "LK Wittenberg"
    },
    {
        ags: "16051",
        county: "SK Erfurt"
    },
    {
        ags: "16052",
        county: "SK Gera"
    },
    {
        ags: "16053",
        county: "SK Jena"
    },
    {
        ags: "16054",
        county: "SK Suhl"
    },
    {
        ags: "16055",
        county: "SK Weimar"
    },
    {
        ags: "16056",
        county: "SK Eisenach"
    },
    {
        ags: "16061",
        county: "LK Eichsfeld"
    },
    {
        ags: "16062",
        county: "LK Nordhausen"
    },
    {
        ags: "16063",
        county: "LK Wartburgkreis"
    },
    {
        ags: "16064",
        county: "LK Unstrut-Hainich-Kreis"
    },
    {
        ags: "16065",
        county: "LK Kyffhäuserkreis"
    },
    {
        ags: "16066",
        county: "LK Schmalkalden-Meiningen"
    },
    {
        ags: "16067",
        county: "LK Gotha"
    },
    {
        ags: "16068",
        county: "LK Sömmerda"
    },
    {
        ags: "16069",
        county: "LK Hildburghausen"
    },
    {
        ags: "16070",
        county: "LK Ilm-Kreis"
    },
    {
        ags: "16071",
        county: "LK Weimarer Land"
    },
    {
        ags: "16072",
        county: "LK Sonneberg"
    },
    {
        ags: "16073",
        county: "LK Saalfeld-Rudolstadt"
    },
    {
        ags: "16074",
        county: "LK Saale-Holzland-Kreis"
    },
    {
        ags: "16075",
        county: "LK Saale-Orla-Kreis"
    },
    {
        ags: "16076",
        county: "LK Greiz"
    },
    {
        ags: "16077",
        county: "LK Altenburger Land"
    },
    {
        ags: "01001",
        county: "SK Flensburg"
    },
    {
        ags: "01002",
        county: "SK Kiel"
    },
    {
        ags: "01003",
        county: "SK Lübeck"
    },
    {
        ags: "01004",
        county: "SK Neumünster"
    },
    {
        ags: "01051",
        county: "LK Dithmarschen"
    },
    {
        ags: "01053",
        county: "LK Herzogtum Lauenburg"
    },
    {
        ags: "01054",
        county: "LK Nordfriesland"
    },
    {
        ags: "01055",
        county: "LK Ostholstein"
    },
    {
        ags: "01056",
        county: "LK Pinneberg"
    },
    {
        ags: "01057",
        county: "LK Plön"
    },
    {
        ags: "01058",
        county: "LK Rendsburg-Eckernförde"
    },
    {
        ags: "01059",
        county: "LK Schleswig-Flensburg"
    },
    {
        ags: "01060",
        county: "LK Segeberg"
    },
    {
        ags: "01061",
        county: "LK Steinburg"
    },
    {
        ags: "01062",
        county: "LK Stormarn"
    },
    {
        ags: "02000",
        county: "SK Hamburg"
    },
    {
        ags: "03101",
        county: "SK Braunschweig"
    },
    {
        ags: "03102",
        county: "SK Salzgitter"
    },
    {
        ags: "03103",
        county: "SK Wolfsburg"
    },
    {
        ags: "03151",
        county: "LK Gifhorn"
    },
    {
        ags: "03153",
        county: "LK Goslar"
    },
    {
        ags: "03154",
        county: "LK Helmstedt"
    },
    {
        ags: "03155",
        county: "LK Northeim"
    },
    {
        ags: "03157",
        county: "LK Peine"
    },
    {
        ags: "03158",
        county: "LK Wolfenbüttel"
    },
    {
        ags: "03159",
        county: "LK Göttingen"
    },
    {
        ags: "03241",
        county: "Region Hannover"
    },
    {
        ags: "03251",
        county: "LK Diepholz"
    },
    {
        ags: "03252",
        county: "LK Hameln-Pyrmont"
    },
    {
        ags: "03254",
        county: "LK Hildesheim"
    },
    {
        ags: "03255",
        county: "LK Holzminden"
    },
    {
        ags: "03256",
        county: "LK Nienburg (Weser)"
    },
    {
        ags: "03257",
        county: "LK Schaumburg"
    },
    {
        ags: "03351",
        county: "LK Celle"
    },
    {
        ags: "03352",
        county: "LK Cuxhaven"
    },
    {
        ags: "03353",
        county: "LK Harburg"
    },
    {
        ags: "03354",
        county: "LK Lüchow-Dannenberg"
    },
    {
        ags: "03355",
        county: "LK Lüneburg"
    },
    {
        ags: "03356",
        county: "LK Osterholz"
    },
    {
        ags: "03357",
        county: "LK Rotenburg (Wümme)"
    },
    {
        ags: "03358",
        county: "LK Heidekreis"
    },
    {
        ags: "03359",
        county: "LK Stade"
    },
    {
        ags: "03360",
        county: "LK Uelzen"
    },
    {
        ags: "03361",
        county: "LK Verden"
    },
    {
        ags: "03401",
        county: "SK Delmenhorst"
    },
    {
        ags: "03402",
        county: "SK Emden"
    },
    {
        ags: "03403",
        county: "SK Oldenburg"
    },
    {
        ags: "03404",
        county: "SK Osnabrück"
    },
    {
        ags: "03405",
        county: "SK Wilhelmshaven"
    },
    {
        ags: "03451",
        county: "LK Ammerland"
    },
    {
        ags: "03452",
        county: "LK Aurich"
    },
    {
        ags: "03453",
        county: "LK Cloppenburg"
    },
    {
        ags: "03454",
        county: "LK Emsland"
    },
    {
        ags: "03455",
        county: "LK Friesland"
    },
    {
        ags: "03456",
        county: "LK Grafschaft Bentheim"
    },
    {
        ags: "03457",
        county: "LK Leer"
    },
    {
        ags: "03458",
        county: "LK Oldenburg"
    },
    {
        ags: "03459",
        county: "LK Osnabrück"
    },
    {
        ags: "03460",
        county: "LK Vechta"
    },
    {
        ags: "03461",
        county: "LK Wesermarsch"
    },
    {
        ags: "03462",
        county: "LK Wittmund"
    },
    {
        ags: "04011",
        county: "SK Bremen"
    },
    {
        ags: "04012",
        county: "SK Bremerhaven"
    },
    {
        ags: "05111",
        county: "SK Düsseldorf"
    },
    {
        ags: "05112",
        county: "SK Duisburg"
    },
    {
        ags: "05113",
        county: "SK Essen"
    },
    {
        ags: "05114",
        county: "SK Krefeld"
    },
    {
        ags: "05116",
        county: "SK Mönchengladbach"
    },
    {
        ags: "05117",
        county: "SK Mülheim a.d.Ruhr"
    },
    {
        ags: "05119",
        county: "SK Oberhausen"
    },
    {
        ags: "05120",
        county: "SK Remscheid"
    },
    {
        ags: "05122",
        county: "SK Solingen"
    },
    {
        ags: "05124",
        county: "SK Wuppertal"
    },
    {
        ags: "05154",
        county: "LK Kleve"
    },
    {
        ags: "05158",
        county: "LK Mettmann"
    },
    {
        ags: "05162",
        county: "LK Rhein-Kreis Neuss"
    },
    {
        ags: "05166",
        county: "LK Viersen"
    },
    {
        ags: "05170",
        county: "LK Wesel"
    },
    {
        ags: "05314",
        county: "SK Bonn"
    },
    {
        ags: "05315",
        county: "SK Köln"
    },
    {
        ags: "05316",
        county: "SK Leverkusen"
    },
    {
        ags: "05334",
        county: "StadtRegion Aachen"
    },
    {
        ags: "05358",
        county: "LK Düren"
    },
    {
        ags: "05362",
        county: "LK Rhein-Erft-Kreis"
    },
    {
        ags: "05366",
        county: "LK Euskirchen"
    },
    {
        ags: "05370",
        county: "LK Heinsberg"
    },
    {
        ags: "05374",
        county: "LK Oberbergischer Kreis"
    },
    {
        ags: "05378",
        county: "LK Rheinisch-Bergischer Kreis"
    },
    {
        ags: "05382",
        county: "LK Rhein-Sieg-Kreis"
    },
    {
        ags: "05512",
        county: "SK Bottrop"
    },
    {
        ags: "05513",
        county: "SK Gelsenkirchen"
    },
    {
        ags: "05515",
        county: "SK Münster"
    },
    {
        ags: "05554",
        county: "LK Borken"
    },
    {
        ags: "05558",
        county: "LK Coesfeld"
    },
    {
        ags: "05562",
        county: "LK Recklinghausen"
    },
    {
        ags: "05566",
        county: "LK Steinfurt"
    },
    {
        ags: "05570",
        county: "LK Warendorf"
    },
    {
        ags: "05711",
        county: "SK Bielefeld"
    },
    {
        ags: "05754",
        county: "LK Gütersloh"
    },
    {
        ags: "05758",
        county: "LK Herford"
    },
    {
        ags: "05762",
        county: "LK Höxter"
    },
    {
        ags: "05766",
        county: "LK Lippe"
    },
    {
        ags: "05770",
        county: "LK Minden-Lübbecke"
    },
    {
        ags: "05774",
        county: "LK Paderborn"
    },
    {
        ags: "05911",
        county: "SK Bochum"
    },
    {
        ags: "05913",
        county: "SK Dortmund"
    },
    {
        ags: "05914",
        county: "SK Hagen"
    },
    {
        ags: "05915",
        county: "SK Hamm"
    },
    {
        ags: "05916",
        county: "SK Herne"
    },
    {
        ags: "05954",
        county: "LK Ennepe-Ruhr-Kreis"
    },
    {
        ags: "05958",
        county: "LK Hochsauerlandkreis"
    },
    {
        ags: "05962",
        county: "LK Märkischer Kreis"
    },
    {
        ags: "05966",
        county: "LK Olpe"
    },
    {
        ags: "05970",
        county: "LK Siegen-Wittgenstein"
    },
    {
        ags: "05974",
        county: "LK Soest"
    },
    {
        ags: "05978",
        county: "LK Unna"
    },
    {
        ags: "06411",
        county: "SK Darmstadt"
    },
    {
        ags: "06412",
        county: "SK Frankfurt am Main"
    },
    {
        ags: "06413",
        county: "SK Offenbach"
    },
    {
        ags: "06414",
        county: "SK Wiesbaden"
    },
    {
        ags: "06431",
        county: "LK Bergstraße"
    },
    {
        ags: "06432",
        county: "LK Darmstadt-Dieburg"
    },
    {
        ags: "06433",
        county: "LK Groß-Gerau"
    },
    {
        ags: "06434",
        county: "LK Hochtaunuskreis"
    },
    {
        ags: "06435",
        county: "LK Main-Kinzig-Kreis"
    },
    {
        ags: "06436",
        county: "LK Main-Taunus-Kreis"
    },
    {
        ags: "06437",
        county: "LK Odenwaldkreis"
    },
    {
        ags: "06438",
        county: "LK Offenbach"
    },
    {
        ags: "06439",
        county: "LK Rheingau-Taunus-Kreis"
    },
    {
        ags: "06440",
        county: "LK Wetteraukreis"
    },
    {
        ags: "06531",
        county: "LK Gießen"
    },
    {
        ags: "06532",
        county: "LK Lahn-Dill-Kreis"
    },
    {
        ags: "06533",
        county: "LK Limburg-Weilburg"
    },
    {
        ags: "06534",
        county: "LK Marburg-Biedenkopf"
    },
    {
        ags: "06535",
        county: "LK Vogelsbergkreis"
    },
    {
        ags: "06611",
        county: "SK Kassel"
    },
    {
        ags: "06631",
        county: "LK Fulda"
    },
    {
        ags: "06632",
        county: "LK Hersfeld-Rotenburg"
    },
    {
        ags: "06633",
        county: "LK Kassel"
    },
    {
        ags: "06634",
        county: "LK Schwalm-Eder-Kreis"
    },
    {
        ags: "06635",
        county: "LK Waldeck-Frankenberg"
    },
    {
        ags: "06636",
        county: "LK Werra-Meißner-Kreis"
    },
    {
        ags: "07111",
        county: "SK Koblenz"
    },
    {
        ags: "07131",
        county: "LK Ahrweiler"
    },
    {
        ags: "07132",
        county: "LK Altenkirchen"
    },
    {
        ags: "07133",
        county: "LK Bad Kreuznach"
    },
    {
        ags: "07134",
        county: "LK Birkenfeld"
    },
    {
        ags: "07135",
        county: "LK Cochem-Zell"
    },
    {
        ags: "07137",
        county: "LK Mayen-Koblenz"
    },
    {
        ags: "07138",
        county: "LK Neuwied"
    },
    {
        ags: "07140",
        county: "LK Rhein-Hunsrück-Kreis"
    },
    {
        ags: "07141",
        county: "LK Rhein-Lahn-Kreis"
    },
    {
        ags: "07143",
        county: "LK Westerwaldkreis"
    },
    {
        ags: "07211",
        county: "SK Trier"
    },
    {
        ags: "07231",
        county: "LK Bernkastel-Wittlich"
    },
    {
        ags: "07232",
        county: "LK Bitburg-Prüm"
    },
    {
        ags: "07233",
        county: "LK Vulkaneifel"
    },
    {
        ags: "07235",
        county: "LK Trier-Saarburg"
    },
    {
        ags: "07311",
        county: "SK Frankenthal"
    },
    {
        ags: "07312",
        county: "SK Kaiserslautern"
    },
    {
        ags: "07313",
        county: "SK Landau i.d.Pfalz"
    },
    {
        ags: "07314",
        county: "SK Ludwigshafen"
    },
    {
        ags: "07315",
        county: "SK Mainz"
    },
    {
        ags: "07316",
        county: "SK Neustadt a.d.Weinstraße"
    },
    {
        ags: "07317",
        county: "SK Pirmasens"
    },
    {
        ags: "07318",
        county: "SK Speyer"
    },
    {
        ags: "07319",
        county: "SK Worms"
    },
    {
        ags: "07320",
        county: "SK Zweibrücken"
    },
    {
        ags: "07331",
        county: "LK Alzey-Worms"
    },
    {
        ags: "07332",
        county: "LK Bad Dürkheim"
    },
    {
        ags: "07333",
        county: "LK Donnersbergkreis"
    },
    {
        ags: "07334",
        county: "LK Germersheim"
    },
    {
        ags: "07335",
        county: "LK Kaiserslautern"
    },
    {
        ags: "07336",
        county: "LK Kusel"
    },
    {
        ags: "07337",
        county: "LK Südliche Weinstraße"
    },
    {
        ags: "07338",
        county: "LK Rhein-Pfalz-Kreis"
    },
    {
        ags: "07339",
        county: "LK Mainz-Bingen"
    },
    {
        ags: "07340",
        county: "LK Südwestpfalz"
    },
    {
        ags: "08111",
        county: "SK Stuttgart"
    },
    {
        ags: "08115",
        county: "LK Böblingen"
    },
    {
        ags: "08116",
        county: "LK Esslingen"
    },
    {
        ags: "08117",
        county: "LK Göppingen"
    },
    {
        ags: "08118",
        county: "LK Ludwigsburg"
    },
    {
        ags: "08119",
        county: "LK Rems-Murr-Kreis"
    },
    {
        ags: "08121",
        county: "SK Heilbronn"
    },
    {
        ags: "08125",
        county: "LK Heilbronn"
    },
    {
        ags: "08126",
        county: "LK Hohenlohekreis"
    },
    {
        ags: "08127",
        county: "LK Schwäbisch Hall"
    },
    {
        ags: "08128",
        county: "LK Main-Tauber-Kreis"
    },
    {
        ags: "08135",
        county: "LK Heidenheim"
    },
    {
        ags: "08136",
        county: "LK Ostalbkreis"
    },
    {
        ags: "08211",
        county: "SK Baden-Baden"
    },
    {
        ags: "08212",
        county: "SK Karlsruhe"
    },
    {
        ags: "08215",
        county: "LK Karlsruhe"
    },
    {
        ags: "08216",
        county: "LK Rastatt"
    },
    {
        ags: "08221",
        county: "SK Heidelberg"
    },
    {
        ags: "08222",
        county: "SK Mannheim"
    },
    {
        ags: "08225",
        county: "LK Neckar-Odenwald-Kreis"
    },
    {
        ags: "08226",
        county: "LK Rhein-Neckar-Kreis"
    },
    {
        ags: "08231",
        county: "SK Pforzheim"
    },
    {
        ags: "08235",
        county: "LK Calw"
    },
    {
        ags: "08236",
        county: "LK Enzkreis"
    },
    {
        ags: "08237",
        county: "LK Freudenstadt"
    },
    {
        ags: "08311",
        county: "SK Freiburg i.Breisgau"
    },
    {
        ags: "08315",
        county: "LK Breisgau-Hochschwarzwald"
    },
    {
        ags: "08316",
        county: "LK Emmendingen"
    },
    {
        ags: "08317",
        county: "LK Ortenaukreis"
    },
    {
        ags: "08325",
        county: "LK Rottweil"
    },
    {
        ags: "08326",
        county: "LK Schwarzwald-Baar-Kreis"
    },
    {
        ags: "08327",
        county: "LK Tuttlingen"
    },
    {
        ags: "08335",
        county: "LK Konstanz"
    },
    {
        ags: "08336",
        county: "LK Lörrach"
    },
    {
        ags: "08337",
        county: "LK Waldshut"
    },
    {
        ags: "08415",
        county: "LK Reutlingen"
    },
    {
        ags: "08416",
        county: "LK Tübingen"
    },
    {
        ags: "08417",
        county: "LK Zollernalbkreis"
    },
    {
        ags: "08421",
        county: "SK Ulm"
    },
    {
        ags: "08425",
        county: "LK Alb-Donau-Kreis"
    },
    {
        ags: "08426",
        county: "LK Biberach"
    },
    {
        ags: "08435",
        county: "LK Bodenseekreis"
    },
    {
        ags: "08436",
        county: "LK Ravensburg"
    },
    {
        ags: "08437",
        county: "LK Sigmaringen"
    },
    {
        ags: "09161",
        county: "SK Ingolstadt"
    },
    {
        ags: "09162",
        county: "SK München"
    },
    {
        ags: "09163",
        county: "SK Rosenheim"
    },
    {
        ags: "09171",
        county: "LK Altötting"
    },
    {
        ags: "09172",
        county: "LK Berchtesgadener Land"
    },
    {
        ags: "09173",
        county: "LK Bad Tölz-Wolfratshausen"
    },
    {
        ags: "09174",
        county: "LK Dachau"
    },
    {
        ags: "09175",
        county: "LK Ebersberg"
    },
    {
        ags: "09176",
        county: "LK Eichstätt"
    },
    {
        ags: "09177",
        county: "LK Erding"
    },
    {
        ags: "09178",
        county: "LK Freising"
    },
    {
        ags: "09179",
        county: "LK Fürstenfeldbruck"
    },
    {
        ags: "09180",
        county: "LK Garmisch-Partenkirchen"
    },
    {
        ags: "09181",
        county: "LK Landsberg a.Lech"
    },
    {
        ags: "09182",
        county: "LK Miesbach"
    },
    {
        ags: "09183",
        county: "LK Mühldorf a.Inn"
    },
    {
        ags: "09184",
        county: "LK München"
    },
    {
        ags: "09185",
        county: "LK Neuburg-Schrobenhausen"
    },
    {
        ags: "09186",
        county: "LK Pfaffenhofen a.d.Ilm"
    },
    {
        ags: "09187",
        county: "LK Rosenheim"
    },
    {
        ags: "09188",
        county: "LK Starnberg"
    },
    {
        ags: "09189",
        county: "LK Traunstein"
    },
    {
        ags: "09190",
        county: "LK Weilheim-Schongau"
    },
    {
        ags: "09261",
        county: "SK Landshut"
    },
    {
        ags: "09262",
        county: "SK Passau"
    },
    {
        ags: "09263",
        county: "SK Straubing"
    },
    {
        ags: "09271",
        county: "LK Deggendorf"
    },
    {
        ags: "09272",
        county: "LK Freyung-Grafenau"
    },
    {
        ags: "09273",
        county: "LK Kelheim"
    },
    {
        ags: "09274",
        county: "LK Landshut"
    },
    {
        ags: "09275",
        county: "LK Passau"
    },
    {
        ags: "09276",
        county: "LK Regen"
    },
    {
        ags: "09277",
        county: "LK Rottal-Inn"
    },
    {
        ags: "09278",
        county: "LK Straubing-Bogen"
    },
    {
        ags: "09279",
        county: "LK Dingolfing-Landau"
    },
    {
        ags: "09361",
        county: "SK Amberg"
    },
    {
        ags: "09362",
        county: "SK Regensburg"
    },
    {
        ags: "09363",
        county: "SK Weiden i.d.OPf."
    },
    {
        ags: "09371",
        county: "LK Amberg-Sulzbach"
    },
    {
        ags: "09372",
        county: "LK Cham"
    },
    {
        ags: "09373",
        county: "LK Neumarkt i.d.OPf."
    },
    {
        ags: "09374",
        county: "LK Neustadt a.d.Waldnaab"
    },
    {
        ags: "09375",
        county: "LK Regensburg"
    },
    {
        ags: "09376",
        county: "LK Schwandorf"
    },
    {
        ags: "09377",
        county: "LK Tirschenreuth"
    },
    {
        ags: "09461",
        county: "SK Bamberg"
    },
    {
        ags: "09462",
        county: "SK Bayreuth"
    },
    {
        ags: "09463",
        county: "SK Coburg"
    },
    {
        ags: "09464",
        county: "SK Hof"
    },
    {
        ags: "09471",
        county: "LK Bamberg"
    },
    {
        ags: "09472",
        county: "LK Bayreuth"
    },
    {
        ags: "09473",
        county: "LK Coburg"
    },
    {
        ags: "09474",
        county: "LK Forchheim"
    },
    {
        ags: "09475",
        county: "LK Hof"
    },
    {
        ags: "09476",
        county: "LK Kronach"
    },
    {
        ags: "09477",
        county: "LK Kulmbach"
    },
    {
        ags: "09478",
        county: "LK Lichtenfels"
    },
    {
        ags: "09479",
        county: "LK Wunsiedel i.Fichtelgebirge"
    },
    {
        ags: "09561",
        county: "SK Ansbach"
    },
    {
        ags: "09562",
        county: "SK Erlangen"
    },
    {
        ags: "09563",
        county: "SK Fürth"
    },
    {
        ags: "09564",
        county: "SK Nürnberg"
    },
    {
        ags: "09565",
        county: "SK Schwabach"
    },
    {
        ags: "09571",
        county: "LK Ansbach"
    },
    {
        ags: "09572",
        county: "LK Erlangen-Höchstadt"
    },
    {
        ags: "09573",
        county: "LK Fürth"
    },
    {
        ags: "09574",
        county: "LK Nürnberger Land"
    },
    {
        ags: "09575",
        county: "LK Neustadt a.d.Aisch-Bad Windsheim"
    },
    {
        ags: "09576",
        county: "LK Roth"
    },
    {
        ags: "09577",
        county: "LK Weißenburg-Gunzenhausen"
    },
    {
        ags: "09661",
        county: "SK Aschaffenburg"
    },
    {
        ags: "09662",
        county: "SK Schweinfurt"
    },
    {
        ags: "09663",
        county: "SK Würzburg"
    },
    {
        ags: "09671",
        county: "LK Aschaffenburg"
    },
    {
        ags: "09672",
        county: "LK Bad Kissingen"
    },
    {
        ags: "09673",
        county: "LK Rhön-Grabfeld"
    },
    {
        ags: "09674",
        county: "LK Haßberge"
    },
    {
        ags: "09675",
        county: "LK Kitzingen"
    },
    {
        ags: "09676",
        county: "LK Miltenberg"
    },
    {
        ags: "09677",
        county: "LK Main-Spessart"
    },
    {
        ags: "09678",
        county: "LK Schweinfurt"
    },
    {
        ags: "09679",
        county: "LK Würzburg"
    },
    {
        ags: "09761",
        county: "SK Augsburg"
    },
    {
        ags: "09762",
        county: "SK Kaufbeuren"
    },
    {
        ags: "09763",
        county: "SK Kempten"
    },
    {
        ags: "09764",
        county: "SK Memmingen"
    },
    {
        ags: "09771",
        county: "LK Aichach-Friedberg"
    },
    {
        ags: "09772",
        county: "LK Augsburg"
    },
    {
        ags: "09773",
        county: "LK Dillingen a.d.Donau"
    },
    {
        ags: "09774",
        county: "LK Günzburg"
    },
    {
        ags: "09775",
        county: "LK Neu-Ulm"
    },
    {
        ags: "09776",
        county: "LK Lindau"
    },
    {
        ags: "09777",
        county: "LK Ostallgäu"
    },
    {
        ags: "09778",
        county: "LK Unterallgäu"
    },
    {
        ags: "09779",
        county: "LK Donau-Ries"
    },
    {
        ags: "09780",
        county: "LK Oberallgäu"
    }
]