import { Button, IconButton, Snackbar } from '@material-ui/core';
import * as React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router';
import DashboardDeutschland from '../Sars-Cov-2/ueberblick/Dashboard/DashboardDeutschland';
import DashRoutes from '../Sars-Cov-2/ueberblick/DashRoutes';
import WaldseeTodoList from '../waldsee/todolisten/WaldseeTodoList';
import EditTodolists from '../waldsee/todolisten_firebase/Admin/EditTodolists';
import Waldsee from '../waldsee/trinkgeldrechner/Waldsee';
import YoutubeHistory from '../Youtube/YoutubeHistory';
import WaldseeTodoListFire from '../waldsee/todolisten_firebase/WaldseeTodoList';
import WaldseeTodoListFireGenTwo from '../waldsee/todolisten_firebase_gen_2';
import authValidation from '../../functions/authValidation';
import Console from '../Console';
import Impressum from '../Impressum/Impressum';
// import Tabs from '../Sars-Cov-2/Overview/Tabs'

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Routes = ({ firestore, user, firebase, userRoles }) => {
  let query = useQuery();
  const [open, setOpen] = React.useState(true);
  return (
    <>
      {/* <React.Suspense> */}
      <Switch>
        <Route path='/sars-cov-2'>
          <DashRoutes firestore={firestore} user={user} firebase={firebase} />
          {/* <SarsRoutes firestore={firestore} user={user} firebase={firebase}/> */}
        </Route>
        {/* {user && user.uid === '6ORFg1OjvhMZEn9PxM692xKfpHU2' && ( */}
        {authValidation.canEdit(userRoles) && (
          <Route path='/waldsee/todos_fire/edit'>
            <EditTodolists firestore={firestore} user={user} firebase={firebase} userRoles={userRoles} />
          </Route>
        )}
        {/* <Route path='/waldsee/todos_fire'>
          <WaldseeTodoListFire firestore={firestore} user={user} firebase={firebase} userRoles={userRoles} />
        </Route> */}
        <Route path='/waldsee/todos'>
          <WaldseeTodoListFireGenTwo firestore={firestore} user={user} firebase={firebase} userRoles={userRoles} />
        </Route>
        {/* <Route path='/waldsee/todos'>
          <WaldseeTodoList firestore={firestore} user={user} firebase={firebase} />
        </Route> */}
        {/* )} */}
        <Route path='/waldsee'>
          <Waldsee firestore={firestore} user={user} firebase={firebase} />
        </Route>

        <Route path='/youtube'>
          <YoutubeHistory firestore={firestore} user={user} firebase={firebase} />
        </Route>
        <Route exact path='/impressum'>
          <Impressum />
        </Route>
        {((user && user.uid === '6ORFg1OjvhMZEn9PxM692xKfpHU2') || authValidation.canEdit(userRoles)) && (
          <Route path='/console'>
            <Console firestore={firestore} user={user} firebase={firebase} userRoles={userRoles} />
          </Route>
        )}
        {/* <Redirect path='/' to='/sars-cov-2' /> */}
        <Route path='/'>
          <Waldsee firebase={firebase} firestore={firestore} user={user} />
        </Route>
      </Switch>
      {/* </React.Suspense> */}
    </>
  );
};
export default Routes;
