import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Route, Switch } from 'react-router-dom/cjs/react-router-dom.min';
import Showcase from './Showcase';
import Todolist from './Todolist';
import { useRouteMatch } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '1280px',
    margin: 'auto',
  },
}));

const WaldseeTodoListHome = ({ firestore, firebase, user, userRoles }) => {
  const classes = useStyles();
  let { path } = useRouteMatch();

  return (
    <div className={classes.root}>
      <Switch>
        <Route path={`${path}/vorlagen`}>
          <Showcase firestore={firestore} firebase={firebase} user={user} userRoles={userRoles} />
        </Route>
        <Route path={path}>
          <Todolist firestore={firestore} firebase={firebase} user={user} userRoles={userRoles} />
        </Route>
      </Switch>
    </div>
  );
};

export default WaldseeTodoListHome;
