import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid, Typography } from '@material-ui/core';

export default function NewTodo({ open, setOpen, firestore, firebase, user, list }) {
  const [title, setTitle] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [error, setError] = React.useState(false);
  const [checkboxes, setcheckboxes] = React.useState({
    optional: false,
    summer: true,
    winter: true,
    forYou: true,
    forOthers: false,
  });

  const handleChange = (event) => {
    // console.log(event);
    setcheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
  };

  const handleClose = () => {
    setOpen(false);
    setTitle('');
    setDescription('');
    setcheckboxes({
      optional: false,
      summer: true,
      winter: true,
      forYou: true,
      forOthers: false,
    });
  };

  const handleSendClose = () => {
    if (title !== '') {
      firestore
        .collection('Todolisten')
        .doc(list.id)
        .collection('Items')
        .add({
          title: title,
          description: description,
          attributes: checkboxes,
          active: true,
          created: firebase.firestore.FieldValue.serverTimestamp(),
          createdby: user.uid,
          edited: firebase.firestore.FieldValue.serverTimestamp(),
          editedby: user.uid,
        })
        .then((docRef) =>
          firestore
            .collection('Todolisten')
            .doc(list.id)
            .update({
              order: [...list.data.order, docRef.id],
            })
        );
      setOpen(false);
      setTitle('');
      setDescription('');
      setcheckboxes({
        optional: false,
        summer: true,
        winter: true,
        forYou: true,
        forOthers: false,
      });
    } else {
      setError(true);
    }
  };

  React.useEffect(() => {
    if (error && title !== '') {
      setError(false);
    }
  }, [title]);

  const { optional, summer, winter, forYou, forOthers } = checkboxes;
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title'>Neues Todo</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Füge ein Todo zu {list.data.title} {list.data.group} hinzu
        </DialogContentText>
        <TextField
          error={error}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          autoFocus
          margin='dense'
          id='title'
          label='Titel'
          type='text'
          fullWidth
        />
        <TextField
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          margin='dense'
          id='description'
          label='Beschreibung'
          type='text'
          fullWidth
        />
        <FormControl component='fieldset'>
          <FormGroup>
            <FormControlLabel control={<Checkbox checked={optional} onChange={handleChange} name='optional' />} label='Optional' />
            <FormControlLabel control={<Checkbox checked={summer} onChange={handleChange} name='summer' />} label='Sommer' />
            <FormControlLabel control={<Checkbox checked={winter} onChange={handleChange} name='winter' />} label='Winter' />
            <FormControlLabel control={<Checkbox checked={forYou} onChange={handleChange} name='forYou' />} label='Für mich' />
            <FormControlLabel control={<Checkbox checked={forOthers} onChange={handleChange} name='forOthers' />} label='Für andere' />
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          Abbrechen
        </Button>
        <Button onClick={handleSendClose} color='primary'>
          Hinzufügen
        </Button>
      </DialogActions>
    </Dialog>
  );
}
