import 'date-fns';
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Typography, Grid, Button, Paper } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DeleteAlert from './DeleteAlert';
import DataPage from './DataPage';

const useStyles = makeStyles((theme) => ({
  root: {},
  // border: {
  //   borderRadius: theme.spacing(1),
  //   borderStyle: 'solid',
  //   padding: theme.spacing(2),
  //   // maxWidth: '500px',
  // },
  paper: {
    padding: theme.spacing(2),
  },
  header: {
    verticalAlign: 'middle',
  },
}));

const WaldseeAdmin = ({ firestore, firebase, user }) => {
  const classes = useStyles();

  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [reloader, setReloader] = React.useState(false);

  const deleteDoc = () => {
    setReloader(!reloader);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  const handleDateIncrement = () => {
    setSelectedDate(selectedDate.addDays(1));
  };
  const handleDateDecrement = () => {
    setSelectedDate(selectedDate.addDays(-1));
  };

  const handlePageUp = () => {
    if (data.length - 1 > page) setPage(page + 1);
  };

  const handlePageDown = () => {
    if (page > 0) setPage(page - 1);
  };

  // console.log(data);
  React.useEffect(() => {
    setData([]);
    setPage(0)

    var date = new Date(selectedDate);
    date.setUTCHours(0, 0, 0, 0);
    var dateEnd = new Date(selectedDate);
    dateEnd.setUTCHours(23, 59, 59, 9999);
    firestore
      .collection('Trinkgeld')
      .where('created', '>=', date)
      .where('created', '<', dateEnd)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, ' => ', doc.data());
          setData((data) => [...data, [doc.id, doc.data()]]);
        });
      })
      .catch((error) => {
        console.log('Error getting documents: ', error);
      });
  }, [selectedDate, reloader]);

  return (
    <>
      <Paper className={classes.paper}>
        <Grid container spacing={0} justifyContent='space-between'>
          <Grid item xs={12} sm md={12} lg>
            <Typography className={classes.header} variant='h6'>
              Admin Konsole
            </Typography>
          </Grid>
          <Grid item xs>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                // margin='dense'
                disableToolbar
                variant='inline'
                format='dd/MM/yyyy'
                id='date-picker-inline'
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs>
            <Grid container justifyContent='flex-end'>
              <Grid item>
                <IconButton
                  color='primary'
                  aria-label='page backward'
                  onClick={handleDateDecrement}
                >
                  <ArrowBackIcon />
                </IconButton>
                <IconButton
                  color='primary'
                  aria-label='page forward'
                  onClick={handleDateIncrement}
                >
                  <ArrowForwardIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {data.length > 0 && <DataPage data={data} page={page} />}
        <Grid container justifyContent='space-between' spacing={0}>
          <Grid item>
            {data.length > 0 && (
              <DeleteAlert
                document={data[page]}
                firestore={firestore}
                user={user}
                firebase={firebase}
                onDel={() => deleteDoc()}
              />
            )}
          </Grid>
          <Grid item style={{ verticalAlign: 'middle', margin: 'auto' }}>
            {data.length > 0 && (
              <Button variant='outlined' color='secondary'>
                Edit Info
              </Button>
            )}
          </Grid>
          <Grid item>
            {data.length > 1 && (
              <>
                <IconButton
                  color='primary'
                  aria-label='page backward'
                  onClick={handlePageDown}
                >
                  <ArrowBackIcon />
                </IconButton>
                <IconButton
                  color='primary'
                  aria-label='page forward'
                  onClick={handlePageUp}
                >
                  <ArrowForwardIcon />
                </IconButton>
              </>
            )}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
export default WaldseeAdmin;
