import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';
import red from '@material-ui/core/colors/red';
import { amber, blue, blueGrey, grey, orange, yellow } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  yellow: {
    backgroundColor: amber[700],
  },
  grey: {
    backgroundColor: grey[500],
  },
  orange: {
    backgroundColor: orange[800],
  },
  red: {
    backgroundColor: red[500],
  },
  blue: {
    backgroundColor: blue[500],
  },
  lightGrey: {
    backgroundColor: blueGrey[500],
  },
}));

const CustomChip = ({ todo, all, sl }) => {
  const classes = useStyles();
  const chipArray = [];
  // list && chipArray.push(<Chip key={list.id} color='primary' size='small' label={list.data.title}></Chip>);
  // console.log(todo);
  const attributes = Object.keys(todo.data.attributes)
    .sort()
    .reduce((obj, key) => {
      obj[key] = todo.data.attributes[key];
      return obj;
    }, {});
  Object.keys(attributes).map((key, index) => {
    if (attributes[key]) {
      switch (key) {
        case 'summer':
          all && chipArray.push(<Chip key={index} className={classes.yellow} size='small' label={'Sommer'}></Chip>);
          break;
        case 'winter':
          all && chipArray.push(<Chip key={index} className={classes.grey} size='small' label={'Winter'}></Chip>);
          break;
        case 'forOthers':
          (sl || all) && chipArray.push(<Chip key={index} className={classes.orange} size='small' label={'forOthers'}></Chip>);
          break;
        case 'forYou':
          (sl || all) && chipArray.push(<Chip key={index} className={classes.red} size='small' label={'forYou'}></Chip>);
          break;
        case 'optional':
          chipArray.push(<Chip key={index} className={classes.blue} size='small' label={'optional'}></Chip>);
          break;
        default:
          chipArray.push(<Chip key={index} className={classes.ligthGrey} size='small' label={attributes[key]}></Chip>);
          break;
      }
    }
  });

  return chipArray;
};
export default CustomChip;
