import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
  Paper,
  Hidden,
  Dialog,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { v4 as uuidv4 } from 'uuid';
import WaldseeAdmin from './WaldseeAdmin';
import InfoIcon from '@material-ui/icons/Info';
import HowTo from './HowTo';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { mitarbeiter } from './mitarbeiter';
import WaldseeHistory from './WaldseeHistory';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {},
  // grid: {
  //   padding: theme.spacing(2),
  // },
  paper: {
    padding: theme.spacing(2),
  },
  button: {
    marginTop: '10px',
  },
}));

const Waldsee = ({ firestore, firebase, user }) => {
  const classes = useStyles();
  const defaultProps = {
    options: mitarbeiter,
    getOptionLabel: (option) => (option.name ? option.name : ''),
  };
  const [list, setList] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const [trinkgeld, setTrinkgeld] = React.useState('');
  const [trinkgeldFinal, setTrinkgeldFinal] = React.useState('');

  const [show, setShow] = React.useState(false);

  const [ret, setRet] = React.useState();

  const [showLoginAlert, setShowLoginAlert] = React.useState(true);
  const [showLogAlert, setShowLogAlert] = React.useState(true);

  // Side-Effects / Lifecycle
  React.useEffect(() => {
    const alert = localStorage.getItem('showLoginAlert');
    setShowLoginAlert(alert ? JSON.parse(alert) : true);
    const logAlert = localStorage.getItem('showLogAlert');
    setShowLogAlert(logAlert ? JSON.parse(logAlert) : true);
  }, []);
  React.useEffect(() => {
    localStorage.setItem('showLoginAlert', JSON.stringify(false));
  }, [user]);

  React.useEffect(() => {
    if (typeof list !== undefined) {
      setRet(update());
    }
  }, [list, trinkgeldFinal]);

  const update = () => {
    const trink = parseFloat(trinkgeldFinal);

    const kitchen_cash = trink * 0.25;
    const service_cash = trink * 0.75;

    var hours_total = 0;
    for (let i = 0; i < list.length; i++) {
      hours_total += parseFloat(list[i].hours);
    }

    const hourly_rate = service_cash / hours_total;

    let person_cash_arr = [];

    for (let i = 0; i < list.length; i++) {
      person_cash_arr.push({
        name: list[i].name,
        hours: list[i].hours,
        money: hourly_rate * parseFloat(list[i].hours),
      });
    }

    let persons = [];
    let persons_money = [];
    let re = [];

    person_cash_arr.map((value, index) =>
      persons.push(
        <Typography key={index}>
          {value.name} ({parseFloat(value.hours)}h):
        </Typography>
      )
    );
    person_cash_arr.map((value, index) =>
      persons_money.push(
        <Typography key={index}>
          {Math.round(value.money * 100) / 100}€
        </Typography>
      )
    );
    re.push(
      <Grid item>
        <Typography>Trinkgeld:</Typography>
        <Typography>Küche (25%):</Typography>
        <Typography>Service/Theke (75%):</Typography>
        <Typography>Gesamt Stunden:</Typography>
        <Typography gutterBottom>Stundensatz:</Typography>
        <Typography variant='h6'>Aufteilung:</Typography>
        <Typography>Küche:</Typography>
        {persons}
      </Grid>
    );
    re.push(
      <Grid item>
        <Typography>{trink}€</Typography>
        <Typography>{Math.round(kitchen_cash * 100) / 100}€</Typography>
        <Typography>{Math.round(service_cash * 100) / 100}€</Typography>
        <Typography>{hours_total}h</Typography>
        <Typography gutterBottom>{Math.round(hourly_rate * 100) / 100}€/h</Typography>
        <Typography variant='h6' style={{color: 'transparent'}} >-</Typography>
        <Typography>{kitchen_cash}€</Typography>
        {persons_money}
      </Grid>
    );

    const date = new Date();
    if (trink > 0) {
      // console.log(trink, hours_total, hourly_rate, kitchen_cash, service_cash, person_cash_arr);
      firestore.collection('Trinkgeld').add({
        Trinkgeld: trink,
        Gesamt_Stunden: hours_total,
        Stundensatz: hourly_rate,
        Küche: kitchen_cash,
        Service_Theke: service_cash,
        Aufteilung: person_cash_arr,
        created: firebase.firestore.FieldValue.serverTimestamp(),
        user: user ? user.uid : 'anonymous',
        userName: user ? user.displayName : 'anonymous',
        schicht: 'tbd',
      });
    }
    return re;
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const [inputFields, setInputFields] = React.useState([{ id: uuidv4(), name: '', hours: '' }]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log('InputFields', inputFields);
    if (trinkgeld > 0) {
      setList(inputFields);
      setTrinkgeldFinal(trinkgeld);
      setShow(true);
    } else alert('Trinkgeldwert eingeben!');
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });

    setInputFields(newInputFields);
  };

  const handleChangeInputName = (id, value) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.name = value;
      }
      return i;
    });
  };

  const handleAddFields = () => {
    setInputFields([...inputFields, { id: uuidv4(), name: '', hours: '' }]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
  };

  const handleClick = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ textAlign: 'left' }}>
      <Typography variant='h4' style={{ textAlign: 'center' }} gutterBottom>
        Trinkgeldrechner
      </Typography>
      {/* <Divider style={{ marginBottom: '12px' }} /> */}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={user ? (show ? 4 : 8) : show ? 6 : 12}>
          <Paper className={classes.paper}>
            <Grid container spacing={2} justifyContent='space-between'>
              <Grid item xs={12} sm={12} md={user ? 12 : show ? 12 : 6} lg={show ? 12 : 6}>
                <Grid container justifyContent='space-between'>
                  <Grid item>
                    <FormControl variant='outlined' size='small'>
                      <OutlinedInput
                        //   error={values.trinkgeld_error}
                        placeholder='Trinkgeld'
                        id='trinkgeld'
                        value={trinkgeld}
                        onChange={(e) => setTrinkgeld(e.target.value)}
                        endAdornment={<InputAdornment position='end'>€</InputAdornment>}
                        aria-describedby='gesamt-trinkgeld'
                        type='number'
                        inputProps={{
                          'aria-label': 'trinkgeld',
                        }}
                        style={{ width: 150 }}
                      />
                    </FormControl>
                  </Grid>
                  <Hidden mdUp>
                    <Grid item>
                      <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby='alert-dialog-title'
                        aria-describedby='alert-dialog-description'
                      >
                        <DialogContent>
                          <HowTo />
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleClose} color='primary' autoFocus>
                            Ausblenden
                          </Button>
                        </DialogActions>
                      </Dialog>
                      <IconButton onClick={handleClick} aria-label='delete'>
                        <InfoIcon />
                      </IconButton>
                    </Grid>
                  </Hidden>
                </Grid>
                <form className={classes.root} onSubmit={handleSubmit}>
                  {inputFields.map((inputField, index) => (
                    <div key={inputField.id} style={{ paddingTop: '6px', paddingBottom: '6px' }}>
                      {/* <TextField
                        style={{ paddingRight: '6px', width: 100 }}
                        size='small'
                        name='name'
                        placeholder='Name'
                        variant='outlined'
                        value={inputField.name}
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      /> */}
                      <Autocomplete
                        {...defaultProps}
                        id='auto-complete'
                        style={{
                          paddingRight: '6px',
                          width: 140,
                          display: 'inline-block',
                        }}
                        onInputChange={(e, v) => handleChangeInputName(inputField.id, v)}
                        freeSolo
                        autoComplete
                        // debug
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name='name'
                            style={{ verticalAlign: 'middle' }}
                            variant='outlined'
                            size='small'
                            // margin='dense'
                            label='Name'
                            // placeholder='Name'
                          />
                        )}
                      />
                      <TextField
                        style={{ width: 110 }}
                        size='small'
                        name='hours'
                        label='Stunden'
                        // placeholder='Stunden'
                        variant='outlined'
                        type='number'
                        value={inputField.hours}
                        onChange={(event) => handleChangeInput(inputField.id, event)}
                      />
                      {inputFields.length !== 1 ? (
                        <IconButton
                          disabled={inputFields.length === 1}
                          onClick={() => handleRemoveFields(inputField.id)}
                          style={{ padding: '8px' }}
                        >
                          <RemoveIcon color='secondary' />
                        </IconButton>
                      ) : (
                        <IconButton
                          disabled={inputFields.length === 1}
                          onClick={() => handleRemoveFields(inputField.id)}
                          style={{ padding: '8px' }}
                        >
                          <RemoveIcon disabled />
                        </IconButton>
                      )}
                      <IconButton onClick={handleAddFields} style={{ padding: '8px' }}>
                        <AddIcon color='primary' />
                      </IconButton>
                    </div>
                  ))}
                  <Grid container justifyContent='flex-end'>
                    <Button className={classes.button} variant='contained' color='primary' type='submit' onClick={handleSubmit}>
                      Berechnen
                    </Button>
                  </Grid>
                </form>
              </Grid>

              <Hidden smDown>
                <Grid item md={user ? 12 : show ? 12 : 6} lg={show ? 12 : 6}>
                  <HowTo />
                </Grid>
              </Hidden>
            </Grid>
          </Paper>
        </Grid>
        {show && (
          <Grid item xs={12} sm={12} md={user ? 4 : 6}>
            <Paper className={classes.paper}>
              <Grid container spacing={2} justifyContent='flex-start'>
                <Grid item xs={12}><Typography variant='h4' align='center'>Ergebnis</Typography></Grid>
                {ret}
              </Grid>
            </Paper>
          </Grid>
        )}
        {user ? (
          <Grid item xs={12} sm={12} md={4}>
            {user.uid === '6ORFg1OjvhMZEn9PxM692xKfpHU2' ? (
              <WaldseeAdmin firestore={firestore} user={user} firebase={firebase} />
            ) : (
              <WaldseeHistory firestore={firestore} user={user} firebase={firebase} />
            )}
          </Grid>
        ) : showLoginAlert ? (
          <Grid item xs={12}>
            <Alert
              onClose={() => {
                setShowLoginAlert(false) || localStorage.setItem('showLoginAlert', JSON.stringify(false));
              }}
              variant='outlined'
              severity='info'
            >
              Melde dich an um deinen persönlichen Verlauf einsehen zu können.
            </Alert>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          {showLogAlert && (
            <Grid item xs={12}>
              <Alert
                variant='outlined'
                severity='info'
                action={
                  <Button
                    color='inherit'
                    size='small'
                    onClick={() => {
                      setShowLogAlert(false) || localStorage.setItem('showLogAlert', JSON.stringify(false));
                    }}
                  >
                    Ok
                  </Button>
                }
              >
                Mit der Nutzung des Trinkgeldrechners während einer aktiver Internetverbindung erklärst du dich damit einverstanden, dass
                deine Eingaben gespeichert werden.
              </Alert>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};
export default Waldsee;
