import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  ButtonGroup,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Link, useRouteMatch } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  linkStyle: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    '&:hover': { color: theme.palette.secondary.dark },
  },
}));

const DeutschlandKreiseInzidenzTabelle = ({ setError }) => {
  const classes = useStyles();
  const [county, setCounty] = React.useState();
  const [sort, setSort] = React.useState('in');
  const url = 'https://api.corona-zahlen.org/districts';
  React.useEffect(() => {
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        if (response && response.code && response.code === 429)
          setError(response);
        else {
          const ret = [];
          for (var elem in response.data) {
            ret.push({
              ags: response.data[elem].ags,
              county: response.data[elem].county,
              weekIncidence: response.data[elem].weekIncidence,
              name: response.data[elem].name,
            });
          }
          setCounty(ret.sort((a, b) => b.weekIncidence - a.weekIncidence));
        }
      })
      .catch((err) => console.log(err));
  }, []);
  let match = useRouteMatch();

  // console.log(sort);
  return (
    <>
      {county ? (
        <>
          <ButtonGroup variant='outlined'>
            <Button
              onClick={() => {
                setCounty(county.sort((a, b) => b.weekIncidence - a.weekIncidence));
                setSort('in');
              }}
            >
              Inzidenz
            </Button>
            <Button
              onClick={() => {
                setCounty(county.sort((a, b) => a.name.localeCompare(b.name)));
                setSort('le');
              }}
            >
              Lexikographisch
            </Button>
          </ButtonGroup>
          <TableContainer style={{ maxHeight: '85vh' }} component={Paper}>
            <Table stickyHeader aria-label='collapsible table' size='small'>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>Inzidenz</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Kreis</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {county.map((c) => (
                  <TableRow key={c.ags}>
                    <TableCell align='left'>
                      {Math.round(c.weekIncidence * 100) / 100}
                    </TableCell>
                    <TableCell align='left'>
                      {
                        <Link
                          to={`/sars-cov-2/landkreise/${c.county}`}
                          className={classes.linkStyle}
                        >
                          {c.county}
                        </Link>
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : null}
    </>
  );
};
export default DeutschlandKreiseInzidenzTabelle;
