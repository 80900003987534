import React from 'react';
import { Container, Draggable } from 'react-smooth-dnd';
import { arrayMove } from 'react-sortable-hoc';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import { Button, Chip, Divider, Grid, IconButton, ListItemAvatar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import SelectList from './Select';
import EditTodo from './Drawers/EditTodo';
import NewTodo from './Drawers/NewTodo';
import DeleteIcon from '@material-ui/icons/Delete';
import EditList from './Drawers/EditList';
import Add from '@material-ui/icons/Add';
import Edit from '@material-ui/icons/Edit';
import NewList from './Drawers/NewList';
import DeleteTodo from './Drawers/DeleteTodo';

const useStyles = makeStyles((theme) => ({
  root: {},
  title: { marginRight: theme.spacing(2) },
  span: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-all',
    fontFamily: 'inherit',
  },
  listMargin: { marginRight: theme.spacing(2) },
  button: { marginBottom: theme.spacing(2), marginTop: theme.spacing(2.35) },
  chip: { margin: theme.spacing(0.5) },
}));

const ListControl = ({ firestore, firebase, user }) => {
  const classes = useStyles();

  const [todoLists, setTodoLists] = React.useState([]);
  const [list, setList] = React.useState('');
  const [items, setItems] = React.useState([]);
  const [order, setOrder] = React.useState([]);
  const [blockChange, setBlockChange] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openNew, setOpenNew] = React.useState(false);
  const [openListEdit, setOpenListEdit] = React.useState(false);
  const [openListNew, setOpenListNew] = React.useState(false);
  const [todoToEdit, setTodoToEdit] = React.useState();

  React.useEffect(() => {
    firestore
      .collection('Todolisten')
      .orderBy('group', 'asc')
      .onSnapshot((querySnapshot) => {
        setTodoLists([]);
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, ' => ', doc.data());
          setTodoLists((input) => [...input, { id: doc.id, data: doc.data() }]);
        });
      });
    return () => {
      setTodoLists([]);
    };
  }, [user]);

  const onDrop = ({ removedIndex, addedIndex }) => {
    // console.log({ removedIndex, addedIndex });
    setBlockChange(true);
    setOrder((items) => arrayMove(items, removedIndex, addedIndex));
  };

  React.useEffect(() => {
    if (todoLists.find((elem) => elem.id === list)) {
      firestore
        .collection('Todolisten')
        .doc(list)
        .collection('Items')
        .get()
        .then((querySnapshot) => {
          setItems([]);
          querySnapshot.forEach((doc) => {
            // console.log(doc.id + '=>' + doc.data());
            setItems((input) => [...input, { id: doc.id, data: doc.data() }]);
          });
        });
      setOrder(todoLists.find((elem) => elem.id === list).data.order);
    } else {
      setItems([]);
      setOrder([]);
    }
  }, [list]);

  const discard = () => {
    setOrder(todoLists.find((elem) => elem.id === list).data.order);
    setBlockChange(false);
  };

  const saveOrder = () => {
    firestore.collection('Todolisten').doc(list).update({
      order: order,
    });
    setBlockChange(false);
  };

  return (
    <>
      <Grid container>
        {!blockChange ? (
          <Grid item xs={12}>
            <SelectList value={list} setValue={setList} todoLists={todoLists} />
          </Grid>
        ) : (
          <>
            <Grid item xs={6}>
              <Button className={classes.button} onClick={() => discard()} color='secondary' size='large' variant='contained'>
                Verwerfen
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button className={classes.button} onClick={() => saveOrder()} color='primary' size='large' variant='contained'>
                Speichern
              </Button>
            </Grid>
          </>
        )}
      </Grid>
      <List>
        <Container dragHandleSelector='.drag-handle' lockAxis='y' onDrop={onDrop}>
          {order.map((elemOrder, index) => {
            const todo = items.find((item, i) => elemOrder === item.id);
            //   console.log(todo);
            if (todo) {
              return (
                <Draggable key={index}>
                  <ListItem>
                    <ListItemAvatar>
                      <IconButton className='drag-handle'>
                        <DragHandleIcon />
                      </IconButton>
                    </ListItemAvatar>
                    <ListItemText
                      onClick={() => setTodoToEdit(todo) || setOpenEdit(true)}
                      primary={
                        <Typography gutterBottom className={classes.title} variant='h6'>
                          {todo.data.title}
                        </Typography>
                      }
                      secondary={
                        <>
                          <Typography gutterBottom component='pre' className={classes.span} variant='body2' color='textSecondary'>
                            {todo.data.description}
                          </Typography>
                          {todo.data.attributes.forYou && <Chip className={classes.chip} size='small' label='Für Dich' />}
                          {todo.data.attributes.forOthers && <Chip className={classes.chip} size='small' label='Für Andere' />}
                          {todo.data.attributes.summer && <Chip className={classes.chip} size='small' label='Sommer' />}
                          {todo.data.attributes.winter && <Chip className={classes.chip} size='small' label='Winter' />}
                          {todo.data.attributes.optional && <Chip className={classes.chip} size='small' label='Optional' />}
                        </>
                      }
                    />
                    <ListItemSecondaryAction>
                      <DeleteTodo
                        firestore={firestore}
                        firebase={firebase}
                        user={user}
                        list={todoLists.find((elem) => elem.id === list)}
                        items={items}
                        setItems={(x) => setItems(x)}
                        element={todo}
                        order={order}
                        setOrder={(y) => setOrder(y)}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider variant='middle' component='li' />
                </Draggable>
              );
            } else {
              return null;
            }
          })}
        </Container>
      </List>
      <Grid container spacing={2}>
        {list !== '' ? (
          <>
            <Grid item xs={12}>
              <Button startIcon={<Add />} fullWidth color='primary' variant='contained' size='large' onClick={() => setOpenNew(true)}>
                Neues Todo
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button startIcon={<Edit />} fullWidth color='primary' variant='contained' size='large' onClick={() => setOpenListEdit(true)}>
                Liste konfigurieren
              </Button>
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <Button startIcon={<Add />} fullWidth color='primary' variant='contained' size='large' onClick={() => setOpenListNew(true)}>
              Liste hinzufügen
            </Button>
          </Grid>
        )}
      </Grid>
      <EditTodo
        firestore={firestore}
        firebase={firebase}
        user={user}
        open={openEdit}
        setOpen={(x) => setOpenEdit(x)}
        todo={todoToEdit}
        listId={list}
        items={items}
        setItems={(y) => setItems(y)}
      />
      <NewTodo
        firestore={firestore}
        firebase={firebase}
        user={user}
        open={openNew}
        setOpen={(x) => setOpenNew(x)}
        listId={list}
        items={items}
        setItems={(y) => setItems(y)}
        order={order}
        setOrder={(z) => setOrder(z)}
      />
      <EditList
        firestore={firestore}
        firebase={firebase}
        user={user}
        open={openListEdit}
        setOpen={(x) => setOpenListEdit(x)}
        list={todoLists.find((elem) => elem.id === list)}
      />
      <NewList firestore={firestore} firebase={firebase} user={user} open={openListNew} setOpen={(x) => setOpenListNew(x)} />
    </>
  );
};

export default ListControl;
