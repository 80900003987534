import { Card, CardHeader, Grid, IconButton } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Switch, Route } from 'react-router-dom';
import authValidation from '../../functions/authValidation';
import ListControl from './ListControl';
import UserControl from './UserControl';

const console = ({ firestore, firebase, user, userRoles }) => {
  let { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <Route exact path={`${path}/user`}>
          <UserControl firestore={firestore} firebase={firebase} user={user} />
        </Route>
        <Route exact path={`${path}/listen`}>
          <ListControl firestore={firestore} firebase={firebase} user={user} />
        </Route>
        <Route path={`${path}`}>
          <Grid container spacing={2}>
            {authValidation.isAdmin(userRoles) && (
              <Grid item xs={12}>
                <ConsoleCard path={path} title={'Nutzer*innen'} location={'user'} />
              </Grid>
            )}
            <Grid item xs={12}>
              <ConsoleCard path={path} title={'Listenverwaltung'} location={'listen'} />
            </Grid>
          </Grid>
        </Route>
      </Switch>
    </>
  );
};

export default console;

function ConsoleCard({ path, title, location }) {
  return (
    <Card style={{ textAlign: 'left' }}>
      <CardHeader
        title={title}
        action={
          <IconButton color='primary' component={Link} to={`${path}/` + location}>
            <ArrowForward />
          </IconButton>
        }
      />
    </Card>
  );
}
