import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const CustomChip = ({ checked, type }) => {
  const classes = useStyles();
  return <Chip color={checked ? 'default' : type === 'custom' ? 'secondary' : 'primary'} size='small' label={type}></Chip>;
};
export default CustomChip;
