import { Button, Divider, Grid, IconButton, Paper, Typography, Checkbox } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import NewTodo from './NewTodo';
import EditTodo from './EditTodo';
import DeleteAlert from './DeleteAlert';
import ArchiveIcon from '@material-ui/icons/Archive';
import { useSnackbar } from 'notistack';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import EditIcon from '@material-ui/icons/Edit';
import EditList from './EditList';
import authValidation from '../../../../functions/authValidation';
import CustomChip from '../CustomChip';

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
  dividerLarge: {
    marginBottom: theme.spacing(4),
  },
}));
const DisplayList = ({ firestore, firebase, user, listId, list, userRoles }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleQueue = (test, type, action) => {
    action ? enqueueSnackbar(test, { variant: type, action }) : enqueueSnackbar(test, { variant: type });
  };
  const [items, setItems] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState([]);
  const [openEditList, setOpenEditList] = React.useState(false);
  const [checkbox, setCheckbox] = React.useState(list.data.active);

  const handleSetEditOpen = (index) => {
    const copy = [...openEdit];
    copy[index] = !copy[index];
    setOpenEdit(copy);
  };
  React.useEffect(() => {
    firestore
      .collection('Todolisten')
      .doc(listId)
      .collection('Items')
      .where('active', '==', true)
      .onSnapshot((querySnapshot) => {
        setItems([]);
        querySnapshot.forEach((doc) => {
          //   console.log(doc.id, ' => ', doc.data());
          setOpenEdit((input) => [...input, false]);
          setItems((input) => [...input, { id: doc.id, data: doc.data() }]);
        });
      });
    return () => {
      setItems([]);
    };
  }, [listId]);

  const changeOrderOfTwoElements = (a, b) => {
    var customOrder = [...list.data.order];

    var tmp = customOrder[a];
    customOrder[a] = customOrder[b];
    customOrder[b] = tmp;

    firestore.collection('Todolisten').doc(list.id).update({
      order: customOrder,
    });
  };

  const handleCheck = () => {
    firestore.collection('Todolisten').doc(list.id).update({
      active: !checkbox,
    });
  };

  React.useEffect(() => {
    setCheckbox(list.data.active);
  }, [list.data.active]);

  return (
    <>
      <Grid container spacing={1}>
        {authValidation.canDelete(userRoles) && (
          <Grid item xs={12} sm>
            <Grid container spacing={1} justifyContent='space-between' alignItems='center'>
              <Grid item xs>
                <Button onClick={() => setOpenEditList(!openEditList)} variant='contained' color='primary' fullWidth>
                  Liste bearbeiten
                </Button>
              </Grid>
              <Grid item>
                <Checkbox size='small' checked={checkbox} onChange={() => handleCheck()} />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Button onClick={() => setOpen(!open)} variant='contained' color='primary' fullWidth>
            Neues Todo
          </Button>
        </Grid>
      </Grid>

      <EditList firestore={firestore} firebase={firebase} user={user} list={list} open={openEditList} setOpen={() => setOpenEditList()} />
      <NewTodo open={open} setOpen={setOpen} firestore={firestore} firebase={firebase} user={user} list={list} />
      {items.length !== 0 &&
        list.data.order.map((itemId, index) => (
          <React.Fragment key={index}>
            {items.find((elem) => elem.id === itemId) && (
              <div className={classes.paper}>
                <EditTodo
                  open={openEdit[index]}
                  setOpen={() => handleSetEditOpen(index)}
                  firestore={firestore}
                  firebase={firebase}
                  user={user}
                  list={list}
                  todo={items.find((elem) => elem.id === itemId)}
                />
                <Paper>
                  <Grid className={classes.grid} spacing={1} container alignItems='center' justifyContent='space-between'>
                    <Grid item container direction='column' xs={1} alignItems='flex-start'>
                      <Grid item>
                        <IconButton size='small' disabled={index === 0} onClick={() => changeOrderOfTwoElements(index, index - 1)}>
                          <ArrowUpwardIcon />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <Divider className={classes.dividerLarge} />
                      </Grid>
                      <Grid item>
                        <IconButton
                          size='small'
                          disabled={index === items.length - 1}
                          onClick={() => changeOrderOfTwoElements(index, index + 1)}
                        >
                          <ArrowDownwardIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Grid item xs={10} container direction='column' spacing={2} alignItems='flex-start'>
                      <Grid item>
                        <CustomChip todo={items.find((elem) => elem.id === itemId)} all sl list={list} />
                      </Grid>
                      <Grid item>
                        <Typography variant='h6' align='left'>
                          {items.find((elem) => elem.id === itemId).data.title}
                        </Typography>
                      </Grid>
                      {items.find((elem) => elem.id === itemId).data.description !== '' && (
                        <Grid item>
                          <Typography align='left'>{items.find((elem) => elem.id === itemId).data.description}</Typography>
                        </Grid>
                      )}
                    </Grid>
                    <Grid item container direction='column' xs={1} alignItems='flex-end'>
                      <Grid item>
                        <IconButton size='small' color='primary' aria-label='delete' onClick={() => handleSetEditOpen(index)}>
                          <EditIcon />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <Divider className={classes.divider} />
                      </Grid>
                      <Grid item>
                        <IconButton
                          size='small'
                          color='primary'
                          aria-label='delete'
                          onClick={() => {
                            firestore
                              .collection('Todolisten')
                              .doc(list.id)
                              .update({
                                order: list.data.order.filter((id) => id !== itemId),
                              })
                              .then(() => {
                                handleQueue(
                                  'Todo archiviert',
                                  'success',
                                  <Button
                                    color='inherit'
                                    size='small'
                                    onClick={
                                      () => {
                                        firestore
                                          .collection('Todolisten')
                                          .doc(list.id)
                                          .update({
                                            order: [...list.data.order],
                                          });
                                      } //hier noch die reihnenfolge anpassen
                                    }
                                  >
                                    Undo
                                  </Button>
                                );
                              })
                              .catch((error) => {
                                handleQueue('Todo archivieren fehlgeschlagen' + error, 'error');
                              });
                          }}
                        >
                          <ArchiveIcon />
                        </IconButton>
                      </Grid>
                      {authValidation.canDelete(userRoles) && (
                        <>
                          <Grid item>
                            <Divider className={classes.divider} />
                          </Grid>
                          <Grid item>
                            <DeleteAlert
                              firestore={firestore}
                              firebase={firebase}
                              user={user}
                              list={list}
                              element={items.find((elem) => elem.id === itemId)}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            )}
          </React.Fragment>
        ))}
      {/* <Typography>Archiviert:</Typography> */}
    </>
  );
};

export default DisplayList;
