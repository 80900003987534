import { Checkbox, Table, TableCell, TableRow } from '@material-ui/core';
import React from 'react';

const UserRow = ({ firestore, firebase, user, row }) => {
  const [secureData, setSecuredata] = React.useState([]);

  React.useEffect(() => {
    firestore
      .collection('Nutzer')
      .doc(row.id)
      .collection('secureData')
      .onSnapshot((querySnapshot) => {
        setSecuredata([]);
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          //   console.log(doc.id, ' => ', doc.data());
          setSecuredata((input) => [...input, doc.data()]);
        });
      });
    return () => {
      setSecuredata([]);
    };
  }, [user, row]);

  const handleCheck = (subscriber, schichtleiter, editor, admin) => {
    firestore
      .collection('Nutzer')
      .doc(row.id)
      .collection('secureData')
      .doc('secureDoc')
      .update({
        roles: { subscriber: subscriber || false, schichtleiter: schichtleiter || false, editor: editor || false, admin: admin || false },
      });
  };

  return (
    <>
      {row && (
        <TableRow>
          <TableCell>{row.data.name}</TableCell>
          <TableCell>{row.data.email}</TableCell>
          {secureData && secureData.length !== 0 && (
            <>
              <TableCell>
                <Checkbox
                  onChange={() =>
                    handleCheck(
                      !secureData[0].roles.subscriber,
                      secureData[0].roles.schichtleiter,
                      secureData[0].roles.editor,
                      secureData[0].roles.admin
                    )
                  }
                  checked={secureData[0].roles.subscriber}
                />
              </TableCell>
              <TableCell>
                <Checkbox
                  onChange={() =>
                    handleCheck(
                      secureData[0].roles.subscriber,
                      !secureData[0].roles.schichtleiter,
                      secureData[0].roles.editor,
                      secureData[0].roles.admin
                    )
                  }
                  checked={secureData[0].roles.schichtleiter}
                />
              </TableCell>
              <TableCell>
                <Checkbox
                  onChange={() =>
                    handleCheck(
                      secureData[0].roles.subscriber,
                      secureData[0].roles.schichtleiter,
                      !secureData[0].roles.editor,
                      secureData[0].roles.admin
                    )
                  }
                  checked={secureData[0].roles.editor}
                />
              </TableCell>
              <TableCell>
                <Checkbox
                  onChange={() =>
                    handleCheck(
                      secureData[0].roles.subscriber,
                      secureData[0].roles.schichtleiter,
                      secureData[0].roles.editor,
                      !secureData[0].roles.admin
                    )
                  }
                  checked={secureData[0].roles.admin}
                />
              </TableCell>
            </>
          )}
        </TableRow>
      )}
    </>
  );
};

export default UserRow;
