import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ResponsiveLine } from '@nivo/line';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const SiebenTageInzidenzPlot = ({ url, county, ags, setError, error }) => {
  const classes = useStyles();
  const [history, setHistory] = React.useState();

  // const url = 'https://api.corona-zahlen.org/germany/history/incidence';
  React.useEffect(() => {
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.code && response.code === 429)
          setError(response);
        else {
          var ret = [];
          var name = 'Deutschland';
          if (county) {
            name = response.data[ags].name;
            for (let i = 0; i < response.data[ags].history.length; i++) {
              const d = new Date(response.data[ags].history[i].date);
              const h =
                d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear();

              ret.push({
                x: h,
                y: response.data[ags].history[i].weekIncidence,
              });
            }
          } else {
            for (let i = 0; i < response.data.length; i++) {
              const d = new Date(response.data[i].date);
              const h =
                d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear();

              ret.push({
                x: h,
                y: response.data[i].weekIncidence,
              });
            }
          }
          setHistory({ id: name, data: ret });
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      {error ? null : (
        <>
          <Typography>7-Tage-Inzidenz/100000</Typography>
          <div style={{ height: '13rem', width: '100%' }}>
            {history && (
              <ResponsiveLine
                data={[history]}
                margin={{ top: 10, right: 10, bottom: 50, left: 40 }}
                xScale={{ type: 'time', format: '%d/%m/%Y' }}
                xFormat='time:%d/%m/%Y'
                yScale={{
                  type: 'linear',
                  min: '0',
                  max: 'auto',
                  stacked: false,
                  reverse: false,
                }}
                yFormat=' >-.2f'
                curve='monotoneX'
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  orient: 'bottom',
                  format: '%b %d %y',
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: -45,
                  legend: '',
                  legendOffset: 36,
                  legendPosition: 'middle',
                }}
                axisLeft={{
                  orient: 'left',
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: '',
                  legendOffset: -40,
                  legendPosition: 'middle',
                }}
                colors={{ scheme: 'set2' }}
                pointSize={0}
                pointColor={{ theme: 'background' }}
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                pointLabelYOffset={-12}
                enableSlices='x'
                useMesh={true}
                theme={{
                  "background": "#424242",
                  "textColor": "rgba(255, 255, 255, 0.7)",
                  "fontSize": 11,
                  "axis": {
                      "domain": {
                          "line": {
                              "stroke": "rgba(255, 255, 255, 0.5)",
                              "strokeWidth": 1
                          }
                      },
                      "ticks": {
                          "line": {
                              "stroke": "rgba(255, 255, 255, 0.5)",
                              "strokeWidth": 1
                          }
                      }
                  },
                  "grid": {
                      "line": {
                          "stroke": "rgba(255, 255, 255, 0.16)",
                          "strokeWidth": 1
                      }
                  }
                }}
                markers={[
                  {
                    axis: 'y',
                    value: 100,
                    legend: '100',
                    lineStyle: {
                      stroke: '#f44336',
                    },
                    textStyle: {
                      fill: '#f44336',
                    },
                  },
                  {
                    axis: 'y',
                    value: 50,
                    legend: '50',
                    lineStyle: {
                      stroke: '#ff9800',
                    },
                    textStyle: {
                      fill: '#ff9800',
                    },
                  },
                  {
                    axis: 'y',
                    value: 35,
                    legend: '35',
                    lineStyle: {
                      stroke: '#4caf50',
                    },
                    textStyle: {
                      fill: '#4caf50',
                    },
                  },
                ]}
                motionConfig='stiff'
                sliceTooltip={({ slice }) => (
                  <div
                    style={{
                      background: 'white',
                      border: 'solid 1px',
                      borderRadius: '8px',
                      overflow: 'hidden',
                      padding: '4px',
                    }}
                  >
                    <div style={{color: 'rgba(0, 0, 0, 0.87)'}}>{slice.points[0].data.xFormatted}</div>
                    <hr />
                    <div
                      style={{
                        display: 'grid',
                        gap: '5px',
                        gridTemplateColumns: '1fr auto',
                      }}
                    >
                      <div>
                        {slice.points.map((point) => (
                          <div>
                            <span
                              style={{
                                color: point.serieColor,
                              }}
                            >
                              {point.serieId + ': '}
                            </span>
                          </div>
                        ))}
                      </div>
                      <div>
                        {slice.points.map((point) => (
                          <div>
                            {
                              <span
                                style={{
                                  fontWeight: 'bold',
                                  color: 'rgba(0, 0, 0, 0.87)'
                                }}
                              >
                                {point.data.yFormatted}
                              </span>
                            }
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};
export default SiebenTageInzidenzPlot;
