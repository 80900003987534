import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  border: {
    borderStyle: 'solid',
    borderRadius: theme.spacing(1),
    borderWidth: '1px',
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    borderColor: theme.palette.action.disabled,
    marginBottom: theme.spacing(1),
  },
  marginTop: {
    // marginTop: theme.spacing(1),
  }
}));

const convertTime = (date_ob) => {
  let date = ('0' + date_ob.getDate()).slice(-2);
  let month = ('0' + (date_ob.getMonth() + 1)).slice(-2);
  let year = date_ob.getFullYear();
  let hours = ('0' + date_ob.getHours()).slice(-2);
  let minutes = ('0' + date_ob.getMinutes()).slice(-2);
  let seconds = ('0' + date_ob.getSeconds()).slice(-2);
  return date + '.' + month + '.' + year + ' ' + hours + ':' + minutes + ':' + seconds;
};

const DataPage = ({ data, page }) => {
  const classes = useStyles();
  const arr = data[page];

  return (
    <>
      {arr && (
        <div className={classes.border}>
          {typeof arr !== undefined && (
            <>
              <Grid container spacing={2} justifyContent='flex-start'>
                <Grid item xs={6}>
                  <Typography>Trinkgeld:</Typography>
                  <Typography>Stundensatz:</Typography>
                  <Typography>Erstellt am:</Typography>
                  <Typography>Erstellt von:</Typography>
                  {arr[1].Aufteilung.map((person, index) => (
                    <Typography noWrap key={index}>
                      {person.name}: {person.hours}h:
                    </Typography>
                  ))}
                </Grid>
                <Grid item xs={6}>
                  <Typography>{arr[1].Trinkgeld}€</Typography>
                  <Typography>{Math.round(arr[1].Stundensatz * 10) / 10}€/h</Typography>
                  <Typography noWrap>{convertTime(arr[1].created.toDate())}</Typography>
                  <Typography noWrap>{arr[1].userName ? arr[1].userName : 'anonymous'}</Typography>
                  <Divider />
                  {arr[1].Aufteilung.map((person, index) => (
                    <Typography key={index}>{Math.round(person.money * 10) / 10}€</Typography>
                  ))}
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.marginTop} style={{ textAlign: 'right' }}>
                    {page + 1}/{data.length}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </div>
      )}
    </>
  );
};
export default DataPage;
