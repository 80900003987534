import { FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import React from 'react';
import Tile from './Tile';
import authValidation from '../../../../functions/authValidation';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  button: { margin: theme.spacing(1) },
  formControl: { marginBottom: theme.spacing(2) },
}));

const Showcase = ({ firestore, firebase, user, userRoles }) => {
  const classes = useStyles();
  const [lists, setLists] = React.useState([]);
  const [groups, setGroups] = React.useState([]);
  const [select, setSelect] = React.useState('');

  React.useEffect(() => {
    var query = firestore.collection('Todolisten');
    query = query.where('active', '==', true);
    if (!authValidation.canReadPrivilige(userRoles)) {
      query = query.where('public', '==', true);
    }
    query
      .orderBy('group', 'asc')
      .orderBy('sequence', 'asc')
      .onSnapshot((querySnapshot) => {
        setLists([]);
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, ' => ', doc.data());
          setLists((input) => [...input, { id: doc.id, data: doc.data() }]);
        });
      });
    return () => {
      setLists([]);
    };
  }, [user, userRoles]);

  React.useEffect(() => {
    setGroups(lists.map((list) => list.data.group, []));
  }, [lists]);

  const handleChange = (event) => {
    setSelect(event.target.value);
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs>
          <FormControl fullWidth variant='standard' className={classes.formControl}>
            <InputLabel id='choose-todolist'>Filter</InputLabel>
            <Select labelId='choose-todolist' id='chooseList' value={select} onChange={handleChange} label='Liste'>
              <MenuItem className={classes.menuItem} value={''}>
                <Grid container justifyContent='space-between'>
                  <Grid item xs>
                    <Typography variant='body1'>Alle Listen</Typography>
                  </Grid>
                </Grid>
              </MenuItem>
              {groups
                .filter(function (group, pos) {
                  return groups.indexOf(group) == pos;
                })
                .map((group, index) => (
                  <MenuItem className={classes.menuItem} value={group} key={index}>
                    <Grid container justifyContent='space-between'>
                      <Grid item xs>
                        <Typography variant='body1'>{group} </Typography>
                      </Grid>
                    </Grid>
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <IconButton component={Link} to='/waldsee/todos/'>
            <CloseIcon />
          </IconButton>
        </Grid>
        {lists.length !== 0 ? (
          lists
            .filter((list) => select === '' || select === list.data.group)
            .map((list, index) => (
              <Grid item key={index} xs={12}>
                <Tile list={list} userRoles={userRoles} />
              </Grid>
            ))
        ) : (
          <Typography color='textSecondary'>
            Möglicherweise hast du keine Verbindung zum Internet. Stelle diese her damit die Todolisten laden
          </Typography>
        )}
      </Grid>
    </>
  );
};

export default Showcase;
