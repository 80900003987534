import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IconButton, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

export default function DeleteAlert({
  document,
  firestore,
  firebase,
  user,
  onDel,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (id) => {
    firestore
      .collection('Trinkgeld')
      .doc(id)
      .delete()
      .then(() => {
        console.log('Document successfully deleted!');
        onDel();
      })
      .catch((error) => {
        console.error('Error removing document: ', error);
      });

    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label='delete' onClick={handleClickOpen}>
        <DeleteIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {'Unwiderruflich löschen?'}
        </DialogTitle>
        <DialogContent>
          {document && (
            <DialogContentText id='alert-dialog-description'>
              Warnung! Nicht widerherstellbar! Möchtest du das Ticket wirklich
              löschen? <br />
              <br />
              <b>Folgendes Dokument wird gelöscht:</b>
              <br />
              <b>Trinkgeld:</b>
              <br />
              {document[1].Trinkgeld}€<br />
              <b>Erstellt am:</b> <br />
              {document[1].created.toDate().toString()}
              <br />
              <b>DokumentID:</b>
              <br />
              {document[0]}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Abbrechen
          </Button>
          <Button
            onClick={() => handleDelete(document[0])}
            color='primary'
            autoFocus
          >
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
