import React from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Divider,
  Fab,
  Grid,
  Grow,
  IconButton,
  makeStyles,
  Slide,
  TextField,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import ListIcon from '@material-ui/icons/List';
import { useLocation } from 'react-router-dom';
import MenuBookIcon from '@material-ui/icons/MenuBook';

const useStyles = makeStyles((theme) => ({
  root: {},
  inputfield: { marginBottom: theme.spacing(3) },
  card: { textAlign: 'left', width: '100%' },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  divider: { marginTop: theme.spacing(2), marginBottom: theme.spacing(2) },
  button: { margin: theme.spacing(1) },
  extendedIcon: { marginRight: theme.spacing(1) },
  description: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-all',
    fontFamily: 'inherit',
    margin: '0',
  },
}));

const Todolist = ({ firestore, firebase, user, userRoles }) => {
  // Binding
  const classes = useStyles();
  const [todos, setTodos] = React.useState([]);
  const [todoText, setTodoText] = React.useState('');

  // Side-Effects / Lifecycle
  React.useEffect(() => {
    const existingTodos = localStorage.getItem('todos');
    setTodos(existingTodos ? JSON.parse(existingTodos) : []);
  }, []);
  React.useEffect(() => {
    if (todos.length !== 0) {
      localStorage.setItem('todos', JSON.stringify(todos));
    }
  }, [todos]);

  // Events
  const addTodo = (event) => {
    event.preventDefault();
    if (todoText !== '') {
      const next = [{ data: { title: todoText }, checked: false, type: 'custom', id: 0, attributes: { optional: 'false' } }, ...todos];
      setTodos(next);
      localStorage.setItem('todos', JSON.stringify(next));
      setTodoText('');
    }
  };
  const addTodoFromTemplate = (list) => {
    const daten = [];
    firestore
      .collection('Todolisten')
      .doc(list.id)
      .collection('Items')
      .where('active', '==', true)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, ' => ', doc.data());
          daten.push({ id: doc.id, data: doc.data(), checked: false });
        });
        for (const docId of list.data.order) {
          const document = daten.find((doc) => docId === doc.id);
          if (daten.find((doc) => docId === doc.id)) setTodos((input) => [...input, document]);
        }
      });
  };
  const updateTodo = (index, checked) => {
    const existingTodos = JSON.parse(localStorage.getItem('todos'));
    existingTodos[index] = {
      id: existingTodos[index].id,
      data: existingTodos[index].data,
      checked: checked,
      type: existingTodos[index].type,
      attributes: existingTodos[index].attributes,
    };
    setTodos(existingTodos);
    localStorage.setItem('todos', JSON.stringify(existingTodos));
  };

  const { state } = useLocation();
  React.useEffect(() => {
    if (typeof state !== 'undefined') {
      addTodoFromTemplate(state);

      // Hier Firestore update einfügen!
      firestore
        .collection('Todolisten')
        .doc(state.id)
        .collection('Analytics')
        .doc('UserBehaviour')
        .update({ uses: firebase.firestore.FieldValue.increment(1) });

      window.history.replaceState(null, '');
    }
  }, []);

  return (
    <>
      <form onSubmit={addTodo}>
        <Grid spacing={1} className={classes.inputfield} container justifyContent='flex-start' alignItems='center'>
          <Grid item xs className={classes.paddingRight}>
            <TextField
              style={{ margin: '0' }}
              fullWidth
              margin='normal'
              label='Todo hinzufügen'
              value={todoText}
              onChange={(e) => setTodoText(e.target.value)}
              id='input'
              variant='outlined'
            />
          </Grid>
          <Grid item>
            {todoText.length !== 0 ? (
              <Fab color='primary' variant='circular' aria-label='hinzufügen' type='submit' size='medium'>
                <AddIcon />
              </Fab>
            ) : (
              <Fab color='primary' variant='extended' aria-label='zu Vorlage Wechseln' component={Link} to='/waldsee/todos/vorlagen'>
                <MenuBookIcon className={classes.extendedIcon} />
                Vorlagen
              </Fab>
            )}
          </Grid>
        </Grid>
      </form>
      <Grid container spacing={1}>
        {todos.map((todo, index) => !todo.checked && <Todo key={index} todo={todo} index={index} updateTodo={updateTodo} />)}
      </Grid>
      {todos.find((todo) => todo.checked) && todos.find((todo) => !todo.checked) && <Divider className={classes.divider} />}
      <Grid container spacing={1}>
        {todos.map((todo, index) => todo.checked && <Todo key={index} todo={todo} index={index} updateTodo={updateTodo} />)}
      </Grid>
      {todos.length !== 0 && <Divider className={classes.divider} />}
      {todos.find((todo) => todo.checked) && (
        <Button
          color='secondary'
          variant='outlined'
          size='small'
          className={classes.button}
          onClick={() => {
            let next = [];
            for (let i = 0; i < todos.length; i++) {
              if (todos[i].checked) {
                // console.log('deleted: ' + todos[i].text);
              } else {
                next = next.concat(todos[i]);
              }
            }
            localStorage.setItem('todos', JSON.stringify(next)) || setTodos(next);
          }}
        >
          Erledigte Löschen
        </Button>
      )}
      {todos.length !== 0 && (
        <Button
          size='small'
          className={classes.button}
          variant='outlined'
          color='secondary'
          onClick={() => localStorage.setItem('todos', JSON.stringify([])) || setTodos([])}
        >
          Alles Löschen
        </Button>
      )}
    </>
  );
};

export default Todolist;

function Todo({ todo, index, updateTodo }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [transition, setTransition] = React.useState(true);

  const timeout = 300;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Grid item key={index} xs={12}>
      <Grow in={transition} timeout={timeout} direction='up'>
        <Card
          raised
          className={classes.card}
          style={{
            display: 'inline-block',
          }}
        >
          <CardHeader
            title={todo.data?.title}
            titleTypographyProps={{ variant: 'h6' }}
            action={
              todo.data?.description && (
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded,
                  })}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label='mehr anzeigen'
                >
                  <ExpandMoreIcon />
                </IconButton>
              )
            }
            avatar={
              <Checkbox
                checked={todo.checked}
                onChange={() => {
                  setTransition(false);
                  setTimeout(() => updateTodo(index, !todo.checked), timeout);
                }}
              />
            }
          />
          <Collapse in={expanded} timeout='auto' unmountOnExit>
            <CardContent>
              <Typography component='pre' className={classes.description} variant='body2' color='textSecondary'>
                {todo.data?.description}
              </Typography>
            </CardContent>
          </Collapse>
        </Card>
      </Grow>
    </Grid>
  );
}
