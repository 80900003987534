import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import Brightness2Icon from '@material-ui/icons/Brightness2';
import Brightness3Icon from '@material-ui/icons/Brightness3';
import LockIcon from '@material-ui/icons/Lock';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import RemoveIcon from '@material-ui/icons/Remove';
import authValidation from '../../../functions/authValidation';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  linkText: {
    textDecoration: `none`,
    // textTransform: `uppercase`,
  },
}));

export default function ChooseTemplate({ firestore, firebase, open, setOpen, addTodoFromTemplate, user, userRoles }) {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  const [lists, setLists] = React.useState([]);

  const [overview, setOverview] = React.useState();

  React.useEffect(() => {
    var query = firestore.collection('Todolisten');
    query = query.where('active', '==', true);
    if (!authValidation.canReadPrivilige(userRoles)) {
      query = query.where('public', '==', true);
    }
    query.orderBy('group', 'asc').onSnapshot((querySnapshot) => {
      setLists([]);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, ' => ', doc.data());
        setLists((input) => [...input, { id: doc.id, data: doc.data() }]);
      });
    });
    return () => {
      setLists([]);
    };
  }, [user, userRoles]);

  React.useEffect(() => {
    lists.length !== 0 && setOverview(getListOverview());
    return () => {
      setOverview();
    };
  }, [lists]);

  const getListOverview = () => {
    var items = [];
    items.push(
      <ListItem key={lists[0].data.group}>
        <ListItemIcon>
          <RemoveIcon />
        </ListItemIcon>
        <ListItemText primary={lists[0].data.group} />
      </ListItem>
    );
    var subitems = [];
    var remember = lists[0].data.group;

    for (let i = 0; i < lists.length; i++) {
      if (lists[i].data.group !== remember) {
        items.push(
          <List key={lists[i].id} component='div' disablePadding>
            {subitems}
          </List>
        );
        subitems = [];
        items.push(
          <ListItem key={lists[i].data.group}>
            <ListItemIcon>
              <RemoveIcon />
            </ListItemIcon>
            <ListItemText primary={lists[i].data.group} />
          </ListItem>
        );
        remember = lists[i].data.group;
      }
      subitems.push(
        <ListItem key={i} button onClick={() => handleClose() || addTodoFromTemplate(lists[i])} className={classes.nested}>
          <ListItemIcon>
            <RemoveIcon />
          </ListItemIcon>
          <ListItemText primary={lists[i].data.title} />
        </ListItem>
      );
    }
    items.push(
      <List key={lists[lists.length - 1].id + 'end'} component='div' disablePadding>
        {subitems}
      </List>
    );
    return (
      <List component='nav' aria-labelledby='nested-list-subheader' className={classes.root}>
        {items}
      </List>
    );
  };

  return (
    <div>
      <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>{'Schichtauswahl'}</DialogTitle>
        <DialogContent>{overview}</DialogContent>
        <DialogActions>
          {authValidation.canEdit(userRoles) && (
            <Button
              component={Link}
              to='/waldsee/todos_fire/edit'
              className={classes.linkText}
              color='primary'
              variant='contained'
              startIcon={<EditIcon />}
            >
              Bearbeiten
            </Button>
          )}
          <Button variant='contained' onClick={handleClose} color='primary' autoFocus>
            Abbrechen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
