import { Typography } from '@material-ui/core';
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  img: {
    display: 'block',
    borderRadius: '50%',
    overflow: 'hidden',
    objectFit: 'cover',
    height: '40px',
  },
  background: {
    float: 'right',
    backgroundColor: '#282828',
    borderRadius: '1000px',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
}));

const Profile = ({ name, picture }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.background}>
        <div style={{ maxHeight: '100%' }}>
          <img className={classes.img} src={picture} />
        </div>
        <Typography variant='h6' noWrap>
          {name}
        </Typography>
      </div>
    </div>
  );
};
export default Profile;
