import { Divider, List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import * as React from 'react';
import HomeIcon from '@material-ui/icons/Home';
import TimelineIcon from '@material-ui/icons/Timeline';
import { Link } from 'react-router-dom';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import CloudIcon from '@material-ui/icons/Cloud';
import SpeedIcon from '@material-ui/icons/Speed';
import EuroIcon from '@material-ui/icons/Euro';
import ListIcon from '@material-ui/icons/List';
import ComputerIcon from '@material-ui/icons/Computer';

import authValidation from '../../functions/authValidation';

const useStyles = makeStyles((theme) => ({
  linkText: {
    textDecoration: `none`,
    // textTransform: `uppercase`,
    color: theme.palette.text.primary,
  },
}));

const SideBarLinks = ({ onClose, user, userRoles }) => {
  const classes = useStyles();

  return (
    <>
      <List>
        <Link to='/' className={classes.linkText} onClick={() => onClose()}>
          <ListItem button>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={'Startseite'} />
          </ListItem>
        </Link>
        <Link to='/waldsee' className={classes.linkText} onClick={() => onClose()}>
          <ListItem button>
            <ListItemIcon>
              <EuroIcon />
            </ListItemIcon>
            <ListItemText primary={'Trinkgeldrechner'} />
          </ListItem>
        </Link>
        {/* {user && user.uid === '6ORFg1OjvhMZEn9PxM692xKfpHU2' && ( */}
        <Link to='/waldsee/todos' className={classes.linkText} onClick={() => onClose()}>
          <ListItem button>
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>
            <ListItemText primary={'Todolisten'} />
          </ListItem>
        </Link>
        {/* )} */}
        <Link to='/sars-cov-2' className={classes.linkText} onClick={() => onClose()}>
          <ListItem button>
            <ListItemIcon>
              <TimelineIcon />
            </ListItemIcon>
            <ListItemText primary={'Covid-19'} />
          </ListItem>
        </Link>
        {/* <Link to='/wetter' className={classes.linkText} onClick={() => onClose()}>
          <ListItem button>
          <ListItemIcon>
          <WbSunnyIcon /> 
          </ListItemIcon>
          <ListItemText primary={'tbd Wetter'} />
          </ListItem>
          </Link>
          <Link to='/formel1' className={classes.linkText} onClick={() => onClose()}>
          <ListItem button>
          <ListItemIcon>
          <SpeedIcon />
          </ListItemIcon>
          <ListItemText primary={'tbd Formel 1'} />
          </ListItem>
          </Link>
          <Link to='/bundesliga' className={classes.linkText} onClick={() => onClose()}>
          <ListItem button>
            <ListItemIcon>
            <SportsSoccerIcon />
            </ListItemIcon>
            <ListItemText primary={'tbd Bundesliga'} />
            </ListItem>
          </Link> */}
      </List>
      <Divider />
      {authValidation.canEdit(userRoles) && (
        <Link to='/console' className={classes.linkText} onClick={() => onClose()}>
          <ListItem button>
            <ListItemIcon>
              <ComputerIcon />
            </ListItemIcon>
            <ListItemText primary={'Konsole'} />
          </ListItem>
        </Link>
      )}
      <List>
        <Link to='/impressum' className={classes.linkText} onClick={() => onClose()}>
          <ListItem button>
            <ListItemIcon>
              <ContactMailIcon />
            </ListItemIcon>
            <ListItemText primary={'Impressum'} />
          </ListItem>
        </Link>
      </List>
    </>
  );
};
export default SideBarLinks;
