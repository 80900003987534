import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core';

export default function NewList({ open, setOpen, firestore, firebase, user, list }) {
  const [title, setTitle] = React.useState('');
  const [group, setGroup] = React.useState('');
  const [errorTitle, setErrorTitle] = React.useState(false);
  const [errorGroup, setErrorGroup] = React.useState(false);
  const [checkbox, setCheckbox] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
    setTitle('');
    setGroup('');
    setCheckbox(false);
  };

  const handleSendClose = () => {
    if (title !== '' && group !== '') {
      firestore.collection('Todolisten').add({
        title: title,
        group: group,
        public: checkbox,
        active: true,
        order: [],
        created: firebase.firestore.FieldValue.serverTimestamp(),
        edited: firebase.firestore.FieldValue.serverTimestamp(),
      });
      setOpen(false);
      setTitle('');
      setGroup('');
      setCheckbox(false);
    } else {
      title === '' && setErrorTitle(true);
      group === '' && setGroupError(true);
    }
  };

  React.useEffect(() => {
    if (errorTitle && title !== '') {
      setErrorTitle(false);
    }
    if (errorGroup && group !== '') {
      setErrorGroup(false);
    }
  }, [title, group]);

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title'>Neues Liste</DialogTitle>
      <DialogContent>
        <DialogContentText>Bei Gruppe auf Großschreiben achten!</DialogContentText>
        <TextField
          error={errorTitle}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          autoFocus
          margin='dense'
          id='title'
          label='Titel'
          type='text'
          fullWidth
        />
        <TextField
          error={errorGroup}
          value={group}
          onChange={(e) => setGroup(e.target.value)}
          margin='dense'
          id='group'
          label='Gruppe'
          type='text'
          fullWidth
        />
        <FormControlLabel control={<Checkbox checked={checkbox} onChange={(e) => setCheckbox(e.target.checked)} />} label='Öffentlich' />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          Abbrechen
        </Button>
        <Button onClick={handleSendClose} color='primary'>
          Hinzufügen
        </Button>
      </DialogActions>
    </Dialog>
  );
}
