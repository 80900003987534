import { Grid, IconButton, Typography } from '@material-ui/core';
import React from 'react';
import DisplayList from './DisplayList';
import SelectList from './Select';
import AddIcon from '@material-ui/icons/Add';
import NewList from './NewList';
import authValidation from '../../../../functions/authValidation';

const EditTodolists = ({ firestore, firebase, user, userRoles }) => {
  const [todoLists, setTodoLists] = React.useState([]);
  const [list, setList] = React.useState('');
  const [openNewList, setOpenNewList] = React.useState(false);

  React.useEffect(() => {
    firestore
      .collection('Todolisten')
      .orderBy('group', 'asc')
      .onSnapshot((querySnapshot) => {
        setTodoLists([]);
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, ' => ', doc.data());
          setTodoLists((input) => [...input, { id: doc.id, data: doc.data() }]);
        });
      });
    return () => {
      setTodoLists([]);
    };
  }, [user]);

  return (
    <>
      <Typography variant='h4' align='center'>
        Vorlagen bearbeiten:
      </Typography>
      <Grid container alignItems='center' justifyContent='space-between'>
        <Grid item xs>
          <SelectList value={list} setValue={setList} todoLists={todoLists} />
        </Grid>
        {authValidation.canDelete(userRoles) && (
          <Grid item>
            <IconButton edge='end' onClick={() => setOpenNewList(true)}>
              <AddIcon size='large' color='primary' />
            </IconButton>
            <NewList open={openNewList} setOpen={setOpenNewList} firestore={firestore} firebase={firebase} user={user} list={list} />
          </Grid>
        )}
      </Grid>
      {todoLists.find((elem) => elem.id === list) && (
        <DisplayList
          firestore={firestore}
          user={user}
          firebase={firebase}
          listId={list}
          list={todoLists.find((elem) => elem.id === list)}
          userRoles={userRoles}
        />
      )}
    </>
  );
};

export default EditTodolists;
