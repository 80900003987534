import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  legend: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const DeutschlandStatesMap = ({ setError, error }) => {
  const classes = useStyles();
  const [map, setMap] = React.useState();
  const [legend, setLegend] = React.useState();
  const url = 'https://api.corona-zahlen.org/map/districts';
  React.useEffect(() => {
    fetch(url)
      .then((response) => response.blob())
      .then((images) => {
        const outside = URL.createObjectURL(images);
        setMap(outside);
      })
      .catch((err) => console.log(err));
    fetch('https://api.corona-zahlen.org/map/districts/legend')
      .then((response) => response.json())
      .then((response) => {
        if (response && response.code && response.code === 429)
          setError(response);
        else setLegend(response);
      })
      .catch((err) => console.log(err));
  }, []);

  const displayLegend = () => {
    return (
      <Grid container>
        {legend?.incidentRanges.map((value, index) => (
          <Grid item xs={6} className={classes.legend} key={index}>
            <div
              style={{
                backgroundColor: value.color,
                width: '12px',
                height: '12px',
                borderRadius: '50%',
                display: 'inline-block',
                overflow: 'hidden',
                marginRight: '6px',
              }}
            />
            <Typography
              style={{ display: 'inline-block' }}
              variant='subtitle2'
              align='left'
            >
              {value.min + '-' + (value.max ? value.max : '...')}
            </Typography>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <>
      {error ? null : (
        <>
          <Typography>7-Tage-Inzidenz / 100000:</Typography>
          {map && legend ? (
            <>
              <div style={{ maxHeight: '100%', maxWidth: '100%' }}>
                <img style={{ width: '100%' }} src={map} />
              </div>
              {displayLegend()}
            </>
          ) : null}
        </>
      )}
    </>
  );
};
export default DeutschlandStatesMap;
