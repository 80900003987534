import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

export default function SignOut({ auth }) {
  return (
    auth.currentUser && (
      <ListItem button onClick={() => auth.signOut()}>
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary={'Sign Out'} />
      </ListItem>
    )
  );
}
