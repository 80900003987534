import React from 'react';
import { Box, Drawer, Fab, Grid, IconButton, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    padding: theme.spacing(2),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function DeleteTodo({ firestore, firebase, user, list, element, items, setItems, order, setOrder }) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    firestore
      .collection('Todolisten')
      .doc(list.id)
      .update({
        order: list.data.order.filter((id) => id !== element.id),
      }) &&
      firestore
        .collection('Todolisten')
        .doc(list.id)
        .collection('Items')
        .doc(element.id)
        .update({
          active: false,
        })
        .then(() => {
          console.log('Document successfully deleted!');
        })
        .catch((error) => {
          console.error('Error removing document: ', error);
        });

    // Update items
    setOrder((order) => order.filter((id) => id !== element.id));
    setItems((items) => items.filter((item) => item.id !== element.id));

    setOpen(false);
  };

  return (
    <div>
      <IconButton size='small' color='secondary' aria-label='delete' onClick={handleClickOpen}>
        <DeleteIcon />
      </IconButton>
      <Drawer PaperProps={{ square: false }} anchor={'bottom'} open={open} onClose={handleClose}>
        <Box className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography gutterBottom align='center' variant='h4' component='h2'>
                Todo löschen?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Warnung! Nicht widerherstellbar! Möchtest du das Todo wirklich löschen? <br />
                <br />
                <b>Folgendes Dokument wird gelöscht:</b>
                <br />
                <b>Titel:</b>
                <br />
                {element.data.title}
                <br />
                <b>Beschreibung:</b> <br />
                {element.data.description}
                <br />
                <b>DokumentID:</b>
                <br />
                {element.id}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent='space-between' item xs={12}>
                <Fab onClick={handleClose} color='primary' variant='extended'>
                  <CloseIcon className={classes.extendedIcon} />
                  Abbrechen
                </Fab>
                <Fab onClick={() => handleDelete()} color='secondary' variant='extended'>
                  <DeleteIcon className={classes.extendedIcon} />
                  Löschen
                </Fab>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
    </div>
  );
}
